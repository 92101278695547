<template>
  <div class="all_type_td_Field" :style="markupTableMode ? 'width: 70%' : ''">
    <pro-report-multiple-chip-td-field
      v-if="col.type === 'multipleChip'"
      :object-key="col.key"
      :null-object-key="col.nullKey"
      :empty-content="col.emptyContent"
      :value="col.value"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-chip-td-field
      v-else-if="col.type === 'chip'"
      :list="col.list"
      :value="col.value"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-commodity-drop-down-list-td-field
      v-else-if="col.type === 'commodity'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-multiple-commodity-drop-down-list-td-field
      v-else-if="col.type === 'multipleCommodity'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-currency-td-field
      v-else-if="col.type === 'currency'"
      :value="col.value"
      :prefix="
        typeof col.prefix === 'function'
          ? col.prefix(col.value, col, props.row)
          : col.prefix
      "
      :locale="col.locale"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-document-status-td-field
      v-else-if="col.type === 'documentStatus'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
      :module="col.module"
      :code-table-temp="codeTableTemp"
    />

    <pro-report-supplier-status-td-field
      v-else-if="col.type === 'supplierStatus'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
      :code-table-temp="codeTableTemp"
    />

    <pro-report-boolean-td-field
      v-else-if="col.type === 'boolean'"
      :list="col.list"
      :value="col.value"
      :show-bool-null="col.showBoolNull"
      :show-bool-empty="col.showBoolEmpty"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-i18n-td-field
      v-else-if="col.type === 'i18n'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-float-td-field
      v-else-if="col.type === 'float'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
      :round="col.round"
    />

    <pro-report-hcm-user-id-td-field
      v-else-if="col.type === 'hcmUserId'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
      :code-table-temp="codeTableTemp"
    />

    <pro-report-file-size-td-field
      v-else-if="col.type === 'fileSize'"
      :value="col.value"
      :filter-option="filterOption[col.field]"
    />

    <pro-report-date-time-null-td-field
      v-else-if="col.type === 'datetimeNull'"
      :value="col.value"
    />

    <pro-report-date-time-td-field
      v-else-if="col.type === 'datetime'"
      :value="col.value"
    />

    <pro-report-sub-report-td-field
      v-else-if="col.type === 'subProReport' && col.value && col.value.list"
      :loading="loading"
      :value="col.value.list"
      :sub-column-header="col.subColumnHeader"
      :sub-row-key="col.key"
      :markup-table-mode="markupTableMode"
    />

    <span v-else>
      {{ col.value }}
    </span>
  </div>
</template>

<script>
import AllTdFieldComponent from "./index.js";
export default {
  name: "ProReportAllTdField",

  props: {
    col: {
      type: Object,
      required: true,
    },

    //for sub report
    loading: {
      type: Boolean,
      default: false,
    },

    markupTableMode: {
      type: Boolean,
      default: false,
    },

    codeTableTemp: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      required: true,
    },
    filterOption: {},
  },

  components: { ...AllTdFieldComponent },
};
</script>

<style lang="scss" scoped></style>
