<template>
  <div class="row" style="width: 100%; padding-left: 10px">
    <div
      :class="showFrameset && showForm ? 'col-8' : 'col-12'"
      style="word-break: break-all"
    >
      <q-card
        v-if="showForm"
        bordered
        class="my-card"
        flat
        style="margin-bottom: 10px"
      >
        <q-card-section class="q-pt-none">
          <!--          <div class="text-h4 q-mt-sm q-mb-xs">-->
          <!--            {{ getRes("Form.Section.ApplicationForm") }}-->
          <!--          </div>-->
          <pro-tender-form-render
            v-if="formName"
            :code="formName"
            :formDataPromise="formDataPromise()"
            :tenderId="tenderId"
            mode="View"
          />
        </q-card-section>
      </q-card>
    </div>

    <div
      :class="showFrameset && showForm ? 'col-4' : 'col-12'"
      :style="showFrameset && showForm ? 'padding-left: 10px;' : ''"
    >
      <q-card bordered class="my-card" flat>
        <q-card-section v-if="md5 === ''">
          <q-skeleton height="40px" style="margin-top: 10px" />
          <q-skeleton height="150px" style="margin-top: 10px" />
          <q-skeleton height="40px" style="margin-top: 10px" />
          <q-skeleton height="150px" style="margin-top: 10px" />
        </q-card-section>
        <q-card-section v-else class="q-pt-xs">
          <div class="text-h4 q-mt-sm q-mb-xs">
            {{ getRes("Form.Section.AuditTrails") }}
            <q-btn
              color="dark"
              dense
              flat
              icon="download"
              no-caps
              round
              style="float: right"
              @click="downloadAuditTrail()"
            />
          </div>
          <div class="q-mt-sm q-mb-xs" style="padding-left: 16px">
            <pro-key-value-table
              :data="auditTrailInfo"
              :rows="auditTrailsRows"
            />
          </div>
          <div v-if="auditTrailInfo.activityId === ''">
            <email-log-audit-trail
              v-if="email.length > 0"
              :email="email"
              :emailInfo="emailInfo"
            ></email-log-audit-trail>

            <button-log-audit-trail
              v-if="replySlip.length > 0"
              :record="replySlip"
              :title="getRes('Form.Section.ReplySlip')"
            ></button-log-audit-trail>

            <button-log-audit-trail
              v-if="submitInterest.length > 0"
              :record="submitInterest"
              :title="getRes('Form.Section.SubmitInterest')"
            ></button-log-audit-trail>

            <signature-audit-trail v-if="signature" :signature="signature" />

            <supplier-signature-audit-trail
              v-if="supplierSignature"
              :supplier-signature="supplierSignature"
            />

            <opening-witness-audit-trail
              v-if="openingWitnessInfo"
              :opening-witness-info="openingWitnessInfo"
            />
          </div>
          <supporting-document-audit-trail
            v-if="supportingDocument.length > 0"
            :supporting-document="supportingDocument"
          />
          <email-attachment-audit-trail
            v-if="emailAttachment.length > 0"
            :email-attachment="emailAttachment"
          />

          <form-attachment-audit-trail
            v-if="formAttachment.length > 0"
            :form-attachment="formAttachment"
          />
          <error-message-audit-trail
            v-if="auditTrailInfo.errorMessage"
            :error-message="auditTrailInfo.errorMessage"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";
import EmailLogAuditTrail from "@/components/PROSmart/AuditTrail/EmailLogAuditTrail";
import ButtonLogAuditTrail from "@/components/PROSmart/AuditTrail/ButtonLogAuditTrail";
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";
import SignatureAuditTrail from "@/components/PROSmart/AuditTrail/SignatureAuditTrail";
import SupportingDocumentAuditTrail from "@/components/PROSmart/AuditTrail/SupportingDocumentAuditTrail";
import EmailAttachmentAuditTrail from "@/components/PROSmart/AuditTrail/EmailAttachmentAuditTrail";
import FormAttachmentAuditTrail from "@/components/PROSmart/AuditTrail/FormAttachmentAuditTrail";
import OpeningWitnessAuditTrail from "@/components/PROSmart/AuditTrail/OpeningWitnessAuditTrail";
import ErrorMessageAuditTrail from "@/components/PROSmart/AuditTrail/ErrorMessageAuditTrail.vue";
import SupplierSignatureAuditTrail from "@/components/PROSmart/AuditTrail/SupplierSignatureAuditTrail.vue";

export default {
  name: "AuditTrailRecord",
  components: {
    ErrorMessageAuditTrail,
    ProKeyValueTable,
    FormAttachmentAuditTrail,
    EmailAttachmentAuditTrail,
    SupportingDocumentAuditTrail,
    SignatureAuditTrail,
    EmailLogAuditTrail,
    ButtonLogAuditTrail,
    ProTenderFormRender,
    OpeningWitnessAuditTrail,
    SupplierSignatureAuditTrail,
  },
  props: {
    processId: String,
    tenderId: [String, Number],
    logId: String,
    showForm: {
      type: Boolean,
      default: false,
    },
    showFrameset: {
      type: Boolean,
      default: false,
    },
    viewFormName: {
      type: Boolean,
      default: false,
    },
    showFormName: {
      type: String,
      default: "",
    },
    isTenderer: {
      type: Boolean,
      default: false,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      auditTrailInfo: {},
      md5: "",
      email: [],
      emailInfo: [],
      replySlip: [],
      submitInterest: [],
      formName: "",
      modelJson: "",
      signature: "",
      supplierSignature: "",
      openingWitnessInfo: "",
      supportingDocument: [],
      emailAttachment: [],
      formAttachment: [],
    };
  },
  methods: {
    getApiClass() {
      if (this.isTenderer) {
        return {
          downloadAuditTrailFile:
            this.$proSmart.download.downloadTendererAuditTrailFile,
          getAuditTrailRecord:
            this.$proSmart.documentUi.getTendererAuditTrailRecord,
        };
      } else if (this.isUser) {
        return {
          downloadAuditTrailFile:
            this.$proSmart.download.downloadUserAuditTrailFile,
          getAuditTrailRecord:
            this.$proSmart.auditTrail.getUserAuditTrailRecord,
        };
      } else {
        return {
          downloadAuditTrailFile:
            this.$proSmart.download.downloadAuditTrailFile,
          getAuditTrailRecord:
            this.logId != null
              ? this.$proSmart.documentUi.getAuditTrailRecordById
              : this.$proSmart.documentUi.getAuditTrailRecord,
        };
      }
    },
    formDataPromise() {
      this.modelJson.IsAuditTrail = true;
      return Promise.resolve(this.modelJson);
    },
    downloadAuditTrail() {
      if (this.isUser) {
        this.getApiClass().downloadAuditTrailFile(
          this,
          this.processId,
          this.logId
        );
      } else {
        this.getApiClass().downloadAuditTrailFile(
          this,
          this.tenderId,
          this.processId,
          this.logId
        );
      }
    },
    load() {
      let auditTrailRecord = {};
      if (this.isTenderer) {
        auditTrailRecord = this.getApiClass().getAuditTrailRecord(
          this,
          this.tenderId,
          this.processId
        );
      } else if (this.isUser) {
        auditTrailRecord = this.getApiClass().getAuditTrailRecord(
          this,
          this.logId
        );
      } else {
        auditTrailRecord = this.getApiClass().getAuditTrailRecord(
          this,
          this.tenderId,
          this.processId,
          this.logId
        );

        this.$proSmart.documentUi
          .getEmailAuditInfo(this, this.tenderId, this.processId)
          .then((info) => {
            info.forEach((b) => this.emailInfo.push(b));
          });
      }

      auditTrailRecord.then((b) => {
        console.log("FormName: " + b.formName);
        this.email = b.email;
        this.replySlip = b.replySlip;
        this.submitInterest = b.submitInterest;
        this.signature = b.signatureAuditTrail;
        this.openingWitnessInfo = b.openingWitnessAuditTrail;
        this.supplierSignature = b.supplierSignatureAuditTrail;
        this.supportingDocument = b.supportingDocument;
        this.emailAttachment = b.emailAttachment;
        this.formName = this.viewFormName
          ? this.showFormName
            ? this.showFormName
            : b.formName + "_View"
          : b.formName;
        this.modelJson = JSON.parse(b.modelJson);
        this.formAttachment = b.formAttachment;
        this.md5 = b.md5;
        this.auditTrailInfo = {
          id: this.logId,
          processId: this.processId,
          submittedOn: b.submittedOn,
          submittedBy: this.multilingualUserName(
            this.modelJson.EName,
            this.modelJson.CName,
            this.modelJson.TName
          ),
          md5: b.md5,
          description: b.formDisplayName,
          activityId: b.activityId,
          formStatus: this.getFormStatus(b.formStatus),
          errorMessage: b.errorMessage,
        };
      });
    },
    multilingualUserName(eName, cName, tName) {
      let language = localStorage.getItem("Language");
      if (language !== undefined) {
        switch (language) {
          case "English":
            return eName;
          case "Chinese":
            return cName;
          case "TraditionalChinese":
            return tName;
          default:
            return eName;
        }
      }
    },
    getFormStatus(status) {
      let statusResult = "";
      switch (status) {
        case "StartProcess":
          statusResult = this.getRes("Form.Field.Started");
          break;
        case "ApproveProcess":
          statusResult = this.getRes("Form.Field.Approved");
          break;
        case "RejectProcess":
          statusResult = this.getRes("Form.Field.Rejected");
          break;
        case "Error":
          statusResult = this.getRes("Form.Field.Error");
          break;
        default:
          statusResult = "";
          break;
      }
      return statusResult;
    },
  },
  created() {
    this.load();
  },

  computed: {
    auditTrailsRows() {
      return [
        {
          label: "Form.Field.ProcessId",
          field: "processId",
          _style: "width:184px",
          class: "q-pl-sm",
        },
        {
          label: "Form.Field.RecordTypeDescription",
          field: "description",
          _style: "width:184px",
          class: "q-pl-sm",
        },
        {
          label: "Form.Field.SubmittedBy",
          field: "submittedBy",
          _style: "width:184px",
          class: "q-pl-sm",
        },
        {
          label: "Form.Field.SubmittedOn",
          field: "submittedOn",
          _style: "width:184px",
          style: "padding-left: 10px",
          format: this.$proSmart.common.getFormattedDate,
        },
        {
          label: "Form.Field.RecordRef",
          field: "md5",
          _style: "width:184px",
          style: "padding-left: 10px",
        },
        {
          label: "Form.Field.FormStatus",
          field: "formStatus",
          _style: "width:184px",
          style: "padding-left: 10px",
        },
      ];
    },
  },

  watch: {
    logId() {
      this.load();
    },
  },
};
</script>
