export default {
  methods: {
    async getHcmUserListAndMapping() {
      const { userList } = await this.$proSmart.formBuilder.getAddressBookList(
        this
      );

      let hcmUserList = userList.map((user) => ({
        label: user.code,
        name: user.code.toLowerCase(),
        value: user.id,
      }));

      let hcmUserMapByName = userList.reduce(
        (pre, { id, code }) => ({ ...pre, [code]: id }),
        {}
      );

      let hcmUserMapByHcmUserId = userList.reduce(
        (pre, { id, code }) => ({ ...pre, [id]: code }),
        {}
      );
      return { hcmUserList, hcmUserMapByName, hcmUserMapByHcmUserId };
    },
  },
};
