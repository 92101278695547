<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-5">
      <q-input
        outlined
        dense
        type="number"
        :step="precision"
        v-model.number="searchFloat.min"
        @input="input"
        prefix="Min"
        style="margin-right: 16px"
      />
    </div>
    <div class="col-5">
      <q-input
        outlined
        dense
        type="number"
        :step="precision"
        v-model.number="searchFloat.max"
        @input="input"
        prefix="Max"
        style="margin-left: 16px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportFloatField",
  props: {
    label: {
      type: String,
      required: true,
    },

    value: null,

    field: {
      type: String,
      required: true,
    },

    precision: {
      type: Number,
      default: 0.01,
    },
  },

  data() {
    return {
      searchFloat: { min: null, max: null },
      type: "float",
    };
  },

  created() {
    this.reset();
    this.updateInput();
  },

  methods: {
    reset() {
      this.searchFloat = { min: null, max: null };
    },

    input() {
      this.updateInput();
      this.$emit("autoSearch");
    },

    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchFloat,
        field: this.field,
        searchComponent: this,
      });
    },

    filter(key, list, value) {
      return list.filter((b) => {
        if (
          this.isUndefinedNullAndEmpty(value.min) &&
          this.isUndefinedNullAndEmpty(value.max)
        ) {
          return true;
        } else if (this.isUndefinedNullAndEmpty(value.min)) {
          return b[key] <= value.max;
        } else if (this.isUndefinedNullAndEmpty(value.max)) {
          return b[key] >= value.min;
        } else {
          return value.min <= b[key] && b[key] <= value.max;
        }
      });
    },

    hasFilter(value) {
      return (
        !this.isUndefinedNullAndEmpty(value.min) ||
        !this.isUndefinedNullAndEmpty(value.max)
      );
    },

    isUndefinedNullAndEmpty(obj) {
      return obj === undefined || obj === null || obj === "";
    },

    clearSearchValue() {
      return { min: null, max: null };
    },
    convert(value, rowValue, colSetting) {
      return colSetting.operation(rowValue);
    },
  },
};
</script>
