<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-10">
      <q-chip
        role="button"
        v-for="item in list"
        :key="item.value"
        :color="item.color ? item.color : 'primary'"
        :selected.sync="searchChip[item.value]"
        :text-color="searchChip[item.value] ? 'white' : 'primary'"
        :outline="!searchChip[item.value]"
        @click="
          isShowAll = false;
          input();
        "
        >{{ $t(item.label) }}
      </q-chip>
      <q-icon
        class="cursor-pointer"
        :name="isShowAll ? 'PROSmart-NoShow' : 'PROSmart-Show'"
        style="font-size: 2.5em; color: rgba(0, 0, 0, 0.54)"
        @click="showChip"
      >
      </q-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportChipField.vue",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    /** {{label: string, value: string, color: string,}[]} */
    list: {
      type: Array,
      default: () => [],
    },
    restApiChipCallback: {
      type: Function,
      default: null,
    },
    showEmptySelect: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchChip: {},
      type: "chip",
      isShowAll: true,
    };
  },
  async created() {
    if (this.restApiChipCallback != null) {
      let restApiChipCallback = await this.restApiChipCallback();

      this.searchChip = {};
      this.list = restApiChipCallback;
      this.$emit("update:list", restApiChipCallback);
    }

    this.addEmptySelect();
    this.reset();
    this.updateInput();
  },
  methods: {
    showChip() {
      this.isShowAll = !this.isShowAll;
      this.reset(this.isShowAll);
      this.input();
    },
    reset(selected = true) {
      if (!this.list) {
        return;
      }

      for (let i = 0; i < this.list.length; i++) {
        this.searchChip[this.list[i].value] = selected;
      }
    },
    toExcel(temp, col, value) {
      let filter = col.list.filter((b) => b.value === value);
      if (filter.length === 0) return value;

      return this.$t(filter[0].label);
    },
    input() {
      this.$forceUpdate();
      this.updateInput();

      this.$emit("autoSearch");
    },
    filter(key, list, value) {
      return list.filter(
        (b) =>
          value[b[key]] ||
          ((b[key] === null || b[key] === "") && value["ProSmartOther"])
      );
    },

    hasFilter(value) {
      return value && Object.keys(value).some((key) => !value[key]);
    },

    addEmptySelect() {
      if (this.showEmptySelect) {
        let filter = this.list.filter((b) => b.value === "ProSmartOther");

        if (filter.length === 0) {
          this.list.push({
            label: "Form.Col.Other",
            value: "ProSmartOther",
          });
          this.$emit("update:list", this.list);
        }
      }
    },
    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchChip,
        field: this.field,
        searchComponent: this,
      });
    },
    clearSearchValue(value) {
      for (let valueKey in value) {
        value[valueKey] = true;
      }

      return value;
    },
  },
  watch: {
    list() {
      this.addEmptySelect();
      this.reset();
      this.updateInput();
    },
  },
};
</script>
