import { render, staticRenderFns } from "./ProKeyValueTable.vue?vue&type=template&id=47e6a6f8&scoped=true&"
import script from "./ProKeyValueTable.vue?vue&type=script&lang=js&"
export * from "./ProKeyValueTable.vue?vue&type=script&lang=js&"
import style0 from "./ProKeyValueTable.vue?vue&type=style&index=0&id=47e6a6f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e6a6f8",
  null
  
)

export default component.exports
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMarkupTable,QTr,QTable});
