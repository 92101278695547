<template>
  <div :style="simpleTableMode ? 'width: 100%' : 'width: 100%; padding: 10px'">
    <q-card class="q-mb-md" v-if="title">
      <q-expansion-item header-class="text-h4 text-primary">
        <template v-slot:header>
          <q-item-section role="button">{{ getRes(title) }}</q-item-section>
        </template>
        <q-separator />
        <div class="q-pa-md">
          <div v-for="col in enableColumnList" :key="col.field">
            <!-- all serach field -->
            <pro-report-all-search-field
              :col="col"
              :filter-option="filterOption"
              :code-table-temp="codeTableTemp"
              @handleAutoSearch="autoSearch && search(convertedList)"
            />
          </div>

          <!-- search button -->
          <div class="row justify-end" v-if="!autoSearch">
            <div class="col-auto">
              <q-btn
                no-caps
                color="primary"
                :label="getRes('Search')"
                @click="search(convertedList)"
              />
            </div>
          </div>
        </div>
      </q-expansion-item>
    </q-card>

    <div class="q-pb-sm">
      <div class="row">
        <div
          :class="
            selected.length > 0
              ? parentName === 'SupplierList'
                ? 'col-12  '
                : 'col-5'
              : 'col'
          "
        >
          <q-table
            :loading="loading"
            :data="showList"
            :columns="enableColumnList"
            :row-key="rowKey"
            :pagination="{
              rowsPerPage: simpleTableMode ? 0 : 11,
              sortBy: 'dataTime',
              descending: true,
            }"
            wrap-cells
            :selection="selectable ? 'single' : 'none'"
            :selected.sync="selected"
            :hide-bottom="simpleTableMode"
            :flat="simpleTableMode"
          >
            <template #top-left v-if="!simpleTableMode">
              <div class="text-h4 text-primary">
                {{ getRes("CustMenu.Title.SearchResult") }}
                <q-badge color="primary" align="middle">
                  {{ showList.length }}
                </q-badge>
              </div>
            </template>

            <template #top-right v-if="!simpleTableMode">
              <div v-for="(extension, index) in extensionButton" :key="index">
                <q-btn
                  no-caps
                  dense
                  round
                  flat
                  :icon="extension.icon"
                  @click="extension.click"
                />
              </div>
              <div v-for="(extension, index) in extensionDownload" :key="index">
                <q-btn
                  no-caps
                  dense
                  round
                  flat
                  :icon="extension.icon"
                  @click="
                    downloading(
                      extension.downloadAll,
                      extension.hiddenColumn,
                      extension.appendColumn
                    )
                  "
                />
              </div>

              <q-btn
                v-if="showDownloadToExcel"
                no-caps
                dense
                round
                flat
                icon="download"
                @click="downloading()"
              />

              <q-btn-dropdown
                v-if="showColumnsSetting"
                flat
                dense
                no-caps
                icon="PROSmart-Column"
                @before-show="loadShowColumnSettingList()"
                ref="columnSetting"
              >
                <q-list style="width: 300px" bordered>
                  <q-item-label header>
                    {{ $t("Form.Field.Columns") }}
                  </q-item-label>

                  <q-item
                    tag="label"
                    v-ripple
                    clickable
                    v-for="(col, key) in showColumnSettingList.list"
                    :key="col.label"
                  >
                    <q-item-section side top>
                      <q-checkbox
                        v-model="showColumnSettingList.showList"
                        :val="key"
                      />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>{{ $t(col.label) }}</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section></q-item-section>
                    <q-item-section style="display: contents">
                      <q-btn
                        style="margin-right: 5px"
                        class="col-4"
                        no-caps
                        outline
                        color="primary"
                        :label="$t('System.Button.Cancel')"
                        @click="$refs.columnSetting.hide()"
                      />
                    </q-item-section>
                    <q-item-section style="display: contents">
                      <q-btn
                        class="col-4"
                        no-caps
                        color="primary"
                        :label="$t('System.Button.Save')"
                        @click="saveShowColumnSettingList"
                      />
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </template>

            <template v-slot:header="props">
              <q-tr :props="props" style="white-space: nowrap">
                <q-th v-if="canShowInContext" auto-width />

                <template v-for="col in props.cols">
                  <q-th
                    v-if="!col.showInContext"
                    :key="col.name"
                    :props="props"
                    :class="col.__thClass"
                  >
                    {{ col.label }}
                  </q-th>
                </template>
              </q-tr>
            </template>

            <template v-slot:body="props">
              <q-tr
                :props="props"
                :key="`m_${props.row[rowKey]}`"
                @click="
                  canShowInContext
                    ? (props.expand = !props.expand)
                    : rowClickEvent(props.row)
                "
                :style="
                  selectable &&
                  (props.row.readOnly === undefined ||
                    props.row.readOnly === false)
                    ? 'cursor: pointer'
                    : canShowInContext
                    ? 'cursor: pointer'
                    : ''
                "
                :class="props.row.class"
              >
                <!-- Expand Row Download By SubReport-->
                <q-td
                  auto-width
                  v-if="canShowInContext"
                  style="text-align: center"
                >
                  <q-btn
                    no-caps
                    dense
                    round
                    flat
                    icon="download"
                    @click.stop="downloadingByRow(props.row)"
                  />
                </q-td>

                <template v-for="col in props.cols">
                  <slot
                    v-if="!col.showInContext"
                    :name="`body-cell-${col.name}`"
                    :col="col"
                    :row="props.row"
                    :value="col.value"
                  >
                    <q-td
                      style="width: 200px"
                      :key="col.id"
                      :class="[col.__tdClass, `text-${col.align}`]"
                      :style="col.style"
                    >
                      <pro-report-all-td-field
                        :col="col"
                        :loading="loading"
                        :filter-option="filterOption"
                        :code-table-temp="codeTableTemp"
                        :props="props"
                      />
                    </q-td>
                  </slot>
                </template>
              </q-tr>

              <!-- Expand Row Show In Context -->
              <q-tr
                v-if="props.expand"
                :props="props"
                :key="`e_${props.row[rowKey]}`"
                :class="props.row.class"
              >
                <q-td colspan="100%">
                  <pro-report-markup-table
                    :row="props.row"
                    :cols="props.cols"
                    :loading="loading"
                    :code-table-temp="codeTableTemp"
                    :filter-option="filterOption"
                  />
                </q-td>
              </q-tr>
            </template>

            <template v-slot:bottom-row="props">
              <q-tr v-if="props.cols.some((b) => b.bottomRow)">
                <q-td v-for="col in props.cols" :key="col.field">
                  <div v-if="col.bottomRow && col.type === 'currency'">
                    <span
                      style="float: left"
                      v-text="$t(`CustMenu.ProReport.${col.bottomRow}`)"
                    />
                    <pro-report-currency-td-field
                      :value="bottomRowOperation(col.bottomRow, col.field)"
                      :locale="col.locale"
                      :filter-option="filterOption[col.field]"
                      prefix=""
                    />
                  </div>
                  <span v-else-if="col.bottomRow">{{
                    bottomRowOperation(col.bottomRow, col.field)
                  }}</span>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
        <div v-if="selected.length > 0" class="col-7">
          <slot name="selectedBody" :row="selected[0]"></slot>
        </div>
        <div>
          <slot name="selectedDialog" :row="selected[0]"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//All Search Field
import { componentMap } from "./Search/index.js";
import ProReportAllSearchField from "@/components/PROSmart/Report/Search/ProReportAllSearchField";

//ALl Td Field
import ProReportAllTdField from "@/components/PROSmart/Report/TableTd/ProReportAllTdField";

//codeTableTemp
import documentStatusMappingMixin from "@/components/PROSmart/Report/Mixins/documentStatusMappingMixin";
import supplierStatusMappingMixin from "@/components/PROSmart/Report/Mixins/supplierStatusMappingMixin";
import hcmUserListMixin from "@/components/PROSmart/Report/Mixins/hcmUserListMixin";
import ProReportCurrencyTdField from "@/components/PROSmart/Report/TableTd/ProReportCurrencyTdField.vue";

export default {
  name: "ProReport",
  components: {
    ProReportCurrencyTdField,
    ProReportAllSearchField,
    ProReportAllTdField,
    ProReportMarkupTable: () =>
      import("@/components/PROSmart/Report/ProReportMarkupTable.vue"),
  },
  mixins: [
    documentStatusMappingMixin,
    hcmUserListMixin,
    supplierStatusMappingMixin,
  ],
  props: {
    title: {
      type: String,
    },
    /**
     * @values {Array<{
     *       name: string,
     *       align: ('left'|'right')
     *       label: string,
     *       i18NLabel: string,
     *       field: string,
     *       searchable: boolean,
     *       type: ('string'|'number'|'chip'|'date'|'datetime')
     *       style: string,
     *       sortable: boolean,
     *       list: Array<{label: string, value: string}>,
     *       restApiChipCallback: function,
     *       showEmptySelect: boolean,
     *       key: string,
     *       nullKey: boolean,
     *       defaultShow: boolean,
     *       hiddenInExcel: boolean,
     *       convertTo: ('date'|'datetime')}>
     *       module: string,
     *       round: number,
     *       operation: function,
     *       bottomRow: ('sum'|'average'|'count'|'min'|'max')
     *       },
     */
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
    },
    value: {
      type: Array,
      required: true,
    },
    rowKey: {
      type: String,
      required: true,
    },
    autoSearch: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    parentName: {
      type: String,
      required: false,
    },
    showDownloadToExcel: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      required: false,
    },
    showColumnsSetting: {
      type: Boolean,
      default: false,
    },
    extensionButton: {
      type: Array,
      default: () => [],
    },
    extensionDownload: {
      type: Array,
      default: () => [],
    },
    simpleTableMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterOption: {},
      showList: [],
      convertedList: [],
      selected: [],
      showColumnSettingList: { list: {}, showList: [] },
      codeTableTemp: {
        hcmUserMapByHcmUserId: undefined,
        hcmUserMapByName: undefined,
        documentStatusMapping: undefined,
        supplierStatusMapping: undefined,
        hcmUserList: undefined,
      },
    };
  },
  methods: {
    saveShowColumnSettingList() {
      let columnsSetting = [...this.columns];

      for (let column of columnsSetting) {
        column.defaultShow = this.showColumnSettingList.showList.includes(
          column.name
        );
      }

      this.$set(this, "columns", columnsSetting);

      this.showColumnSettingList.settingShowList = [
        ...this.showColumnSettingList.showList,
      ];

      this.$refs.columnSetting.hide();

      this.nextTickSearch();
    },

    loadShowColumnSettingList() {
      this.showColumnSettingList.showList = [
        ...this.showColumnSettingList.settingShowList,
      ];
    },

    getExcelListFileObj(columns, rowData = null) {
      let obj = {
        /*** @type {{name: string, label: string, type: string, module: string, list: string[]}[]}*/
        columnHeaders: [],
        cells: {},
      };
      obj.columnHeaders = columns;

      obj.columnHeaders.forEach((col) => {
        if (col.subColumnHeader) {
          col.subColumnHeader = col.subColumnHeader.filter(
            (subCol) => subCol.defaultShow
          );
        }

        let toExcel = this.getTypeClass(col.type).toExcel;
        let colData = [];
        let rowValue;

        if (rowData) {
          rowValue = rowData[col.field];
          rowValue = toExcel
            ? toExcel.call(this, this.codeTableTemp, col, rowValue)
            : rowValue;
          colData.push(rowValue);
        } else {
          this.showList.forEach((row) => {
            rowValue = row[col.field];
            rowValue = toExcel
              ? toExcel.call(this, this.codeTableTemp, col, rowValue)
              : rowValue;
            colData.push(rowValue);
          });
        }

        obj.cells[col.field] = colData;
      });

      return obj;
    },

    generateExcelFileToDownload(obj, fileName) {
      const columnHeaders = obj.columnHeaders.map((col) => {
        return {
          type: col.type,
          name: col.name,
          label: col.label,
          subColumnHeaders:
            col.subColumnHeader?.map((subCol) => ({
              type: subCol.type,
              name: subCol.name,
              label: subCol.label,
            })) || [],
        };
      });

      this.$proSmart.commonPage
        .generateExcelFileByList(
          this,
          JSON.stringify(columnHeaders),
          JSON.stringify(obj.cells)
        )
        .then((file) => {
          this.$proSmart.download.processDownloadFile(
            this.appendFileDate(fileName),
            file.content,
            file.type
          );
        });
    },

    downloadingByRow(row) {
      const columns = this.columns.filter((col) => col.defaultShow);
      let obj = this.getExcelListFileObj(columns, row);
      const fileName = this.fileName
        ? this.fileName + "_individual"
        : "report_individual";

      this.generateExcelFileToDownload(obj, fileName);
    },

    downloading(downloadAll = false, hiddenColumn = [], appendColumn = []) {
      const columns = this.columns
        .filter(
          (item) =>
            downloadAll ||
            (item.defaultShow !== false &&
              item.hiddenInExcel !== true &&
              !item.showInContext)
        )
        .filter((item) => !hiddenColumn.includes(item.name));

      let obj = this.getExcelListFileObj(columns);

      appendColumn.forEach((b) =>
        obj.columnHeaders.push({
          type: b.type,
          name: b.name,
          label: b.label,
          module: b.module,
        })
      );

      const fileName = this.fileName ? this.fileName : "report";
      this.generateExcelFileToDownload(obj, fileName);
    },

    appendFileDate(fileName) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      month = month > 9 ? month : "0" + month;
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;

      let dateTime = `${year}${month}${day}_${hour}${minute}`;
      return fileName.replace(/ /g, "_").toLowerCase() + "_" + dateTime;
    },

    rowClickEvent(row) {
      if (!this.selectable) return;
      if (row.onClick) {
        row.onClick();
      } else if (this.$listeners && this.$listeners["row-select-event"]) {
        this.$emit("row-select-event", row);
      } else {
        this.selected = [row];
      }
    },

    getTypeClass(type) {
      if (!componentMap[type]) console.error("Type Missing " + type);
      return componentMap[type].methods;
    },

    deepClone(target, cache = new WeakMap()) {
      if (target === null || target === undefined) return target;
      if (typeof target !== "object") return target;
      if (target instanceof Date) return new Date(target);

      if (cache.get(target)) return cache.get(target);
      const cloneTarget = new target.constructor();
      cache.set(target, cloneTarget);

      Object.keys(target).forEach(
        (key) => (cloneTarget[key] = this.deepClone(target[key], cache))
      );
      return cloneTarget;
    },

    search(searchList) {
      this.selected = [];
      if (
        searchList === null ||
        searchList === undefined ||
        searchList.length === 0
      ) {
        this.showList = [];
        return;
      }
      searchList = this.deepClone(searchList);

      for (let filterOptionKey in this.filterOption) {
        let option = this.filterOption[filterOptionKey];
        let field = option.field;

        let columnsSetting = this.columns.find((b) => b.field === field);
        if (!columnsSetting || columnsSetting.defaultShow === false) continue;

        searchList = this.getTypeClass(option.type).filter(
          filterOptionKey,
          searchList,
          option.value,
          option,
          this.codeTableTemp
        );
      }
      this.showList = searchList;
    },

    convertTo(list) {
      this.showColumnSettingList = {
        list: {},
        showList: [],
        settingShowList: [],
      };

      for (let i = 0; i < this.columns.length; i++) {
        let option = this.columns[i];

        if (option.subColumnHeader) {
          for (let j = 0; j < option.subColumnHeader.length; j++) {
            let subOption = option.subColumnHeader[j];

            if (subOption.i18NLabel) {
              this.$set(subOption, "label", this.$t(subOption.i18NLabel));
            }

            if (!subOption.convertTo) continue;
            let subConvertToClass = this.getTypeClass(subOption.convertTo);

            list.forEach((b) => {
              let subList = b[option.field]?.list || [];
              if (subList.length == 0) return;

              subList.forEach(
                (s) =>
                  (s[subOption.field] = subConvertToClass.convert(
                    s[subOption.field],
                    s,
                    subOption
                  ))
              );
            });
          }
        }

        if (option.i18NLabel) {
          this.$set(option, "label", this.$t(option.i18NLabel));
        }

        this.showColumnSettingList.list[option.name] = {
          label: option.label,
        };

        if (option.defaultShow === undefined || option.defaultShow) {
          this.showColumnSettingList.settingShowList.push(option.name);
        }

        if (!option.convertTo) continue;

        let convertToClass = this.getTypeClass(option.convertTo);

        list = list.map((b) => {
          b[option.field] = convertToClass.convert(b[option.field], b, option);
          return b;
        });
      }

      return list;
    },

    updateColumnsSetting(name, key, value) {
      const find = this.columns.find((b) => b.name === name);

      if (!find) return;

      find[key] = value;

      this.search(this.convertedList);
    },

    nextTickSearch() {
      this.$nextTick(() => {
        this.search(this.convertedList);
      });
    },

    clearSearchFilter() {
      for (let filterOptionKey in this.filterOption) {
        let option = this.filterOption[filterOptionKey];

        option.value = this.getTypeClass(option.type).clearSearchValue(
          option.value,
          option.searchComponent
        );

        option.searchComponent.reset();
        option.searchComponent.$forceUpdate();
      }
      this.nextTickSearch();
    },

    async loadCodeTableTemp(columns) {
      for (let i = 0; i < columns.length; i++) {
        let option = columns[i];

        switch (option.type) {
          case "documentStatus":
            if (!this.codeTableTemp.documentStatusMapping)
              this.codeTableTemp.documentStatusMapping = this.getDocumentStatusTextMap();
            break;
          case "supplierStatus":
            if (!this.codeTableTemp.supplierStatusMapping)
              this.codeTableTemp.supplierStatusMapping = this.getSupplierStatusTextMap();
            break;
          case "hcmUserId":
            if (!this.codeTableTemp.hcmUserList) {
              let {
                hcmUserList,
                hcmUserMapByName,
                hcmUserMapByHcmUserId,
              } = await this.getHcmUserListAndMapping();

              this.codeTableTemp.hcmUserList = hcmUserList;
              this.codeTableTemp.hcmUserMapByName = hcmUserMapByName;
              this.codeTableTemp.hcmUserMapByHcmUserId = hcmUserMapByHcmUserId;
            }

            break;
        }
      }

      this.$forceUpdate();
    },

    bottomRowOperation(operation, colName) {
      let dataList = this.showList.map((b) => b[colName]);

      switch (operation) {
        case "sum":
          return dataList.reduce((partialSum, a) => partialSum + a, 0);
        case "average":
          var sum = dataList.reduce((partialSum, a) => partialSum + a, 0);
          return sum / dataList.length;
        case "count":
          return dataList.length;
        case "min":
          return Math.min(...dataList);
        case "max":
          return Math.max(...dataList);
      }
    },
  },

  async created() {
    await this.loadCodeTableTemp(this.columns);
  },

  mounted() {
    this.convertedList = this.convertTo(this.value);
    this.search(this.convertedList);
  },

  computed: {
    /** @return {Array<{readOnly?:boolean, class?: string, onClick?: Function}>} */
    enableColumnList() {
      return this.columns.filter((b) => !(b.defaultShow === false));
    },

    /** @return {boolean} */
    canShowInContext() {
      return (
        this.columns.filter(
          (b) =>
            b.showInContext && b.subColumnHeader && b.subColumnHeader.length > 0
        ).length > 0
      );
    },
  },

  watch: {
    async columns(newValue) {
      await this.loadCodeTableTemp(newValue);
    },
    value(newValue) {
      this.convertedList = this.convertTo(newValue);
      this.search(this.convertedList);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";

/deep/ {
  th,
  td {
    vertical-align: middle;
  }
}

/deep/ .cust-label-container {
  display: flex;
  align-items: center;
}
</style>
