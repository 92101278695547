<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs">
      {{ $t("Form.Section.TendererSignature") }}
    </div>
    <div class="q-mt-sm q-mb-xs" v-if="signature">
      <q-markup-table dense flat separator="none">
        <thead></thead>
        <tbody>
          <tr class="q-tr--no-hover" v-if="signature.contactPerson">
            <td style="width: 200px">{{ $t("Form.Field.Signer") }}:</td>
            <td class="q-pl-sm" v-text="signature.contactPerson"></td>
          </tr>
          <tr class="q-tr--no-hover">
            <td style="width: 200px">{{ $t("Form.Field.Email") }}:</td>
            <td class="q-pl-sm" v-text="signature.email"></td>
          </tr>
          <tr class="q-tr--no-hover">
            <td style="width: 200px">{{ $t("Form.Field.TendererName") }}:</td>
            <td class="q-pl-sm" v-text="signature.companyName"></td>
          </tr>
          <tr class="q-tr--no-hover">
            <td>{{ $t("Form.Field.Signature") }}:</td>
            <td class="q-pl-sm">
              {{ $t("Form.Field.SignatureDescribe", { type: signature.type })
              }}<br />
            </td>
          </tr>
          <tr class="q-tr--no-hover">
            <td>{{ $t("Form.Field.UsingIPAddress") }}:</td>
            <td class="q-pl-sm" v-text="signature.ip" />
          </tr>
          <tr class="q-tr--no-hover">
            <td>{{ $t("Form.Field.Sent") }}:</td>
            <td
              class="q-pl-sm"
              v-text="$proSmart.common.getFormattedDate(signature.sentDate)"
            />
          </tr>
          <tr class="q-tr--no-hover">
            <td>{{ $t("Form.Field.Signed") }}:</td>
            <td
              class="q-pl-sm"
              v-text="$proSmart.common.getFormattedDate(signature.signedDate)"
            />
          </tr>
        </tbody>
      </q-markup-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignatureAuditTrail",
  props: {
    signature: Object,
  },
};
</script>
