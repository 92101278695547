export default {
  methods: {
    getSupplierStatusTextMap() {
      return {
        Registering: this.getRes("Document.Status.Registering"),
        PendingForApproval: this.getRes("Document.Status.PendingForApproval"),
        Approved: this.getRes("Document.Status.Approved"),
        Inactive: this.getRes("Document.Status.Inactive"),
        Purged: this.getRes("Document.Status.Purged"),
      };
    },
  },
};
