<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs">{{ getRes('Form.Section.FormAttachment') }}</div>
    <div v-for="folder in formAttachment" :key="folder.fileId">
      <pro-file :file="folder" :show-download-button="true" />
    </div>
  </div>
</template>

<script>
import ProFile from "@/components/PROSmart/ProFile";

export default {
  name: "FormAttachmentAuditTrail",
  components: { ProFile },
  props: {
    formAttachment: Array,
  },
};
</script>
