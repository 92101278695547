<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-10">
      <q-input outlined dense v-model="searchString" @input="input"></q-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportTextField.vue",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchString: "",
      type: "string",
    };
  },
  methods: {
    reset() {
      this.searchString = "";
    },

    input() {
      this.$emit("input", {
        type: this.type,
        value: this.searchString,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },

    filter(key, list, value) {
      return list.filter((b) => {
        let v = b[key] === null ? "" : b[key];
        return (
          v.includes(value) || v.toLowerCase().includes(value) || v === value
        );
      });
    },

    hasFilter(value) {
      return value !== "";
    },

    clearSearchValue() {
      return "";
    },
  },
};
</script>
