<template>
  <pro-form-render
    :mode="mode"
    :code="code"
    :tender-id="tenderId"
    :form-data-promise="mergedFormDataPromise"
    :custom-resource-promise="moduleCustomResourcePromise"
    @pro-form-submit="$listeners['pro-form-submit']"
  />
</template>

<script>
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  name: "ProTenderFormRender",
  components: { ProFormRender },
  props: {
    mode: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    tenderId: {
      type: Number,
      required: true,
    },
    formDataPromise: Promise,
  },
  data() {
    return {
      tenderInfoPromise: null,
      mergedFormDataPromise: null,
      moduleCustomResourcePromise: null,
    };
  },
  mounted() {
    this.$watch(
      () => [this.tenderId, this.formDataPromise],
      this.handleUpdateFormData,
      { immediate: true }
    );
    this.$watch(
      () => [this.tenderId, this.code],
      this.handleUpdateCustomResource,
      { immediate: true }
    );
  },
  methods: {
    handleUpdateFormData(newVal, oldVal) {
      if (!oldVal || oldVal[0] !== this.tenderId) {
        this.tenderInfoPromise = this.loadTenderInfo();
      }

      this.mergedFormDataPromise = Promise.all([
        this.tenderInfoPromise,
        this.formDataPromise || Promise.resolve({}),
      ]).then(([tenderInfo, formData]) => ({
        ...tenderInfo,
        ...formData,
        tenderId: this.tenderId,
      }));
    },
    handleUpdateCustomResource() {
      this.moduleCustomResourcePromise = this.loadModuleCustomResource();
    },
    loadTenderInfo() {
      return this.$proSmart.tender
        .getBasicInfo(this, this.tenderId)
        .then((tenderInfo) => {
          if (!tenderInfo) {
            return Promise.reject("Load tender info error");
          }

          return tenderInfo;
        });
    },
    loadModuleCustomResource() {
      return this.$proSmart.formBuilder.getCustomResource(
        this,
        this.tenderId,
        this.code
      );
    },
  },
};
</script>
