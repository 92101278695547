<template>
  <div>
    <pro-report-text-field
      ref="strText"
      v-if="col.searchable && col.type === 'string'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-i18n-field
      ref="i18nText"
      v-if="col.searchable && col.type === 'i18n'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-file-size-field
      ref="fileSizeText"
      v-if="col.searchable && col.type === 'fileSize'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-currency-field
      ref="currencyText"
      v-if="col.searchable && col.type === 'currency'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      :prefix="col.prefix"
      :precision="col.precision"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-float-field
      ref="floatText"
      v-if="col.searchable && col.type === 'float'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      :precision="col.precision"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-hcm-user-id-field
      ref="hcmUserIdText"
      v-if="col.searchable && col.type === 'hcmUserId'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      :is-multiple="col.isMultiple"
      @autoSearch="handleAutoSearch"
      :code-table-temp="codeTableTemp"
    />

    <pro-report-int-field
      ref="numberText"
      v-if="col.searchable && col.type === 'number'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-boolean-field
      ref="booleanText"
      v-if="col.searchable && col.type === 'boolean'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      :show-bool-null="col.showBoolNull"
      :show-bool-empty="col.showBoolEmpty"
      :list.sync="col.list"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-chip-field
      ref="reportChipOptions"
      v-if="col.searchable && col.type === 'chip'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
      :list.sync="col.list"
      :rest-api-chip-callback="col.restApiChipCallback"
      :show-empty-select="col.showEmptySelect"
    />

    <pro-report-multiple-chip-field
      ref="reportMultipleChipOptions"
      v-if="col.searchable && col.type === 'multipleChip'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
      :list.sync="col.list"
      :rest-api-chip-callback="col.restApiChipCallback"
      :show-empty-select="col.showEmptySelect"
      :object-key="col.key"
      :null-object-key="col.nullKey"
      :empty-content="col.emptyContent"
    />

    <pro-report-date-field
      ref="dateText"
      v-if="col.searchable && col.type === 'date'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-date-time-field
      ref="dateTimeText"
      v-if="col.searchable && col.type === 'datetime'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-date-time-null-field
      ref="dateTimeNullFieldOptions"
      v-if="col.searchable && col.type === 'datetimeNull'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-commodity-drop-down-list-field
      ref="dropDownListFieldOptions"
      v-if="col.searchable && col.type === 'commodity'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-multiple-commodity-drop-down-list-field
      ref="multipleDropDownListFieldOptions"
      v-if="col.searchable && col.type === 'multipleCommodity'"
      :label="col.label || $t(col.i18NLabel)"
      v-model="filterOption[col.field]"
      :field="col.field"
      @autoSearch="handleAutoSearch"
    />

    <pro-report-document-status-field
      ref="documentStatusFieldOptions"
      v-if="col.searchable && col.type === 'documentStatus'"
      v-model="filterOption[col.field]"
      @autoSearch="handleAutoSearch"
      :field="col.field"
      :label="col.label || $t(col.i18NLabel)"
      :module.sync="col.module"
      :code-table-temp="codeTableTemp"
    />

    <pro-report-supplier-status-field
      ref="supplierStatusFieldOptions"
      v-if="col.searchable && col.type === 'supplierStatus'"
      v-model="filterOption[col.field]"
      @autoSearch="handleAutoSearch"
      :field="col.field"
      :label="col.label || $t(col.i18NLabel)"
      :code-table-temp="codeTableTemp"
    />

    <pro-report-sub-report-field
      ref="subReportFieldOptions"
      v-if="col.searchable && col.type === 'subProReport'"
      :field="col.field"
      :label="col.label || $t(col.i18NLabel)"
      :sub-columns="col.subColumnHeader"
      v-model="filterOption[col.field]"
      :code-table-temp="codeTableTemp"
      @autoSearch="handleAutoSearch"
    />
  </div>
</template>

<script>
import allSearchField from "./index.js";
export default {
  name: "ProReportAllSearchField",

  props: {
    col: {
      type: Object,
      required: true,
    },

    filterOption: {
      type: Object,
    },

    codeTableTemp: {
      type: Object,
      required: false,
    },
  },

  components: {
    ...allSearchField,
  },

  methods: {
    handleAutoSearch() {
      this.$emit("handleAutoSearch");
    },
  },
};
</script>

<style lang="scss" scoped></style>
