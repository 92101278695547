import { render, staticRenderFns } from "./AuditTrailRecord.vue?vue&type=template&id=14ad3379&"
import script from "./AuditTrailRecord.vue?vue&type=script&lang=js&"
export * from "./AuditTrailRecord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSkeleton,QBtn});
