<template>
  <div>
    <div style="float: left">{{ prefix }}</div>
    <div style="float: right">{{ setCurrencyFormat(value) }}</div>
  </div>
</template>

<script>
export default {
  name: "ProReportCurrencyTdField",
  props: {
    value: null,
    prefix: {
      type: String,
      default: "$",
    },
    locale: {
      type: String,
      default: "en-US",
    },
  },
  methods: {
    setCurrencyFormat(num) {
      if (num === null) return "";

      return num.toLocaleString(this.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
