<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-10">
      <template v-for="item in list">
        <q-chip
          role="button"
          v-if="!nullObjectKey"
          :key="item.value"
          color="primary"
          :selected.sync="searchChip[item.value]"
          :text-color="searchChip[item.value] ? 'white' : 'primary'"
          :outline="!searchChip[item.value]"
          @click="
            isShowAll = false;
            input();
          "
        >
          {{ $t(item.label) }}
        </q-chip>
        <q-chip
          role="button"
          v-else
          :key="item"
          color="primary"
          :selected.sync="searchChip[item]"
          :text-color="searchChip[item] ? 'white' : 'primary'"
          :outline="!searchChip[item]"
          @click="
            isShowAll = false;
            input();
          "
        >
          {{ item ? $t(item) : $t(emptyContent) }}
        </q-chip>
      </template>
      <q-icon
        class="cursor-pointer"
        :name="isShowAll ? 'PROSmart-NoShow' : 'PROSmart-Show'"
        style="font-size: 2.5em; color: rgba(0, 0, 0, 0.54)"
        @click="showChip"
      >
      </q-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportMultipleChipField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    /** {{label: string, value: string}[]} */
    list: {
      type: Array,
      required: true,
    },
    objectKey: {
      type: String,
      default: "value",
    },
    nullObjectKey: {
      type: Boolean,
      default: false,
    },
    emptyContent: null,

    restApiChipCallback: {
      type: Function,
      default: null,
    },
    showEmptySelect: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchChip: {},
      type: "multipleChip",
      isShowAll: true,
    };
  },
  async created() {
    if (this.restApiChipCallback != null) {
      let restApiChipCallback = await this.restApiChipCallback();

      this.searchChip = {};
      this.list = restApiChipCallback;
      this.$emit("update:list", restApiChipCallback);
    }

    this.addEmptySelect();
    this.reset();
    this.updateInput();
  },
  methods: {
    showChip() {
      this.isShowAll = !this.isShowAll;
      this.reset(this.isShowAll);
      this.input();
    },
    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchChip,
        objectKey: this.objectKey,
        nullObjectKey: this.nullObjectKey,
        field: this.field,
        searchComponent: this,
      });
    },
    reset(selected = true) {
      if (!this.list) {
        return;
      }
      for (let i = 0; i < this.list.length; i++) {
        if (!this.nullObjectKey) this.searchChip[this.list[i].value] = selected;
        else this.searchChip[this.list[i]] = selected;
      }
    },
    input() {
      this.$forceUpdate();
      this.updateInput();
      this.$emit("autoSearch");
    },
    filter(key, list, value, option) {
      return list.filter((b) => {
        let groups = b[key];
        if (!option.nullObjectKey) {
          if (groups.length === 0 && value["ProSmartOther"]) return true;
          for (let i = 0; i < groups.length; i++) {
            if (value[groups[i][option.objectKey]]) return true;
          }
          return false;
        } else {
          for (let i = 0; i < groups.length; i++) {
            if (value[groups[i]]) return true;
          }
          return false;
        }
      });
    },
    hasFilter(value) {
      return value && Object.keys(value).some((key) => !value[key]);
    },
    addEmptySelect() {
      if (this.showEmptySelect) {
        let filter = this.list.filter((b) => b.value === "ProSmartOther");
        if (filter.length === 0) {
          this.list.push({
            label: "Form.Col.Other",
            value: "ProSmartOther",
          });
          this.$emit("update:list", this.list);
        }
      }
    },
    clearSearchValue(value) {
      for (let valueKey in value) {
        value[valueKey] = true;
      }

      return value;
    },
    toExcel(temp, col, value) {
      let labelList = [];
      let key = col.key ? col.key : "value";

      for (let i = 0; i < value.length; i++) {
        let filter = col.list.filter((b) => b.value === value[i][key]);
        if (filter.length === 0) {
          labelList.push(value);
        } else {
          labelList.push(filter[0].label);
        }
      }
      return labelList.join(",");
    },
  },
  watch: {
    list() {
      this.addEmptySelect();
      this.reset();
      this.updateInput();
    },
  },
};
</script>
