<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>
    <div class="col-9">
      <drop-down-list
        ref="dropDownListRef"
        :dropDownList="dropDownList"
        @getFilterCode="input"
      />
    </div>
  </div>
</template>
<script>
import DropDownList from "@/components/PROSmart/DropDownList.vue";
import proSmart from "@pro-smart/hcm-custom-project-api-middleware";

const { codeTable } = proSmart;

export default {
  name: "ProReportMultipleCommodityDropDownListField",
  components: { DropDownList },
  props: {
    label: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dropDownList: [],
      filterCommodityCode: "",
      type: "multipleCommodity",
    };
  },
  beforeMount() {
    codeTable.getCommodityCode(this).then((b) => {
      this.dropDownList = b;
    });
  },
  methods: {
    reset() {
      this.$refs.dropDownListRef.reset();
    },
    filter(key, list, value) {
      if (!value) return list;

      return list.filter((item) => {
        if (!item[key].length) return false;

        let fullCommodity = [];
        for (let i = 0; i < item[key].length; i++) {
          if (!item[key][i].code || !item[key][i].code.length) return false;

          for (let j = 0; j < item[key][i].code.length; j++) {
            fullCommodity.push(item[key][i].code.slice(0, j + 1).join("-"));
          }
        }
        fullCommodity = [...new Set(fullCommodity)];
        return fullCommodity.includes(value);
      });
    },
    input(val) {
      this.$emit("input", {
        type: this.type,
        value: val.val,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },

    hasFilter(value) {
      return value !== "";
    },

    clearSearchValue() {
      return "";
    },
    toExcel(temp, col, value) {
      if (value && value.length) {
        const excelValue = value.map((item) => item.value.join(">"));
        return excelValue.join("; ");
      }
      return "";
    },
  },
};
</script>
