export default {
  methods: {
    getDocumentStatusTextMap() {
      return {
        eoi: {
          0: this.getRes("Document.Status.Created"),
          50: this.getRes("Document.Status.Eoi"),
          80: this.getRes("Document.Status.Shortlisted"),
          90: this.getRes("Document.Status.Awarded"),
          150: this.getRes("Document.Status.Cancelled"),
          100: this.getRes("Document.Status.Aborted"),
          110: this.getRes("Document.Status.Closed"),
        },
        pq: {
          0: this.getRes("Document.Status.Created"),
          50: this.getRes("Document.Status.Eoi"),
          80: this.getRes("Document.Status.AssessmentCompleted"),
          90: this.getRes("Document.Status.Awarded"),
          150: this.getRes("Document.Status.Cancelled"),
          100: this.getRes("Document.Status.Aborted"),
          110: this.getRes("Document.Status.Closed"),
        },
        rfq: {
          0: this.getRes("Document.Status.PreTender"),
          50: this.getRes("Document.Status.Tender"),
          80: this.getRes("Document.Status.PostTender"),
          90: this.getRes("Document.Status.Awarded"),
          150: this.getRes("Document.Status.Cancelled"),
          100: this.getRes("Document.Status.Aborted"),
          110: this.getRes("Document.Status.Closed"),
        },
        rfi: {
          0: this.getRes("Document.Status.PreTender"),
          50: this.getRes("Document.Status.Tender"),
          80: this.getRes("Document.Status.PostTender"),
          90: this.getRes("Document.Status.Awarded"),
          150: this.getRes("Document.Status.Cancelled"),
          100: this.getRes("Document.Status.Aborted"),
          110: this.getRes("Document.Status.Closed"),
        },
        tender: {
          0: this.getRes("Document.Status.PreTender"),
          50: this.getRes("Document.Status.Tender"),
          80: this.getRes("Document.Status.PostTender"),
          90: this.getRes("Document.Status.Awarded"),
          150: this.getRes("Document.Status.Cancelled"),
          100: this.getRes("Document.Status.Aborted"),
          110: this.getRes("Document.Status.Closed"),
        },
      };
    },
  },
};
