<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>
    <div class="col-5">
      <pro-smart-date
        v-model="searchDate.from"
        date-type="datetime"
        style="margin-right: 16px"
        @input="input"
        allow-non-working-days
      ></pro-smart-date>
    </div>
    <div class="col-5">
      <pro-smart-date
        v-model="searchDate.to"
        date-type="datetime"
        style="margin-left: 16px"
        @input="input"
        default-time="23:59"
        allow-non-working-days
      ></pro-smart-date>
    </div>
  </div>
</template>

<script>
import ProSmartDate from "@/components/PROSmart/ProSmartDate";

export default {
  name: "ProReportDateTimeNullField",
  components: { ProSmartDate },
  props: {
    label: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    value: null,
  },
  data() {
    return {
      searchDate: { from: null, to: null },
      error: { form: "", to: "" },
      type: "datetimeNull",
    };
  },
  methods: {
    reset() {
      this.searchDate = { from: null, to: null };
      this.error = { from: null, to: null };
    },
    input() {
      this.$emit("input", {
        type: this.type,
        value: this.searchDate,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },
    filter(key, list, value) {
      if (!value.from && !value.to) {
        return list;
      }

      if (!value.from && value.to) {
        return list.filter((b) => {
          return b[key] && b[key] <= new Date(value.to);
        });
      } else if (value.from && !value.to) {
        return list.filter((b) => {
          return b[key] && new Date(value.from) <= b[key];
        });
      } else {
        return list.filter((b) => {
          return (
            b[key] &&
            new Date(value.from) <= b[key] &&
            b[key] <= new Date(value.to)
          );
        });
      }
    },

    hasFilter(value) {
      return value.from || value.to;
    },

    convert(value) {
      return value ? new Date(value) : null;
    },
    clearSearchValue() {
      return { from: null, value: null };
    },
    toExcel(temp, col, value) {
      if (!value) return null;
      return value.getTime();
    },
  },
};
</script>
