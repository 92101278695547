<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-5" style="padding-right: 16px">
      <q-select
        outlined
        dense
        v-model="searchHcmUser"
        :multiple="isMultiple"
        use-chips
        :options="codeTableTemp.hcmUserList"
        @input="input"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportHcmUserIdField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    codeTableTemp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchHcmUser: null,
      type: "hcmUserId",
    };
  },
  created() {
    this.reset();
    this.updateInput();
  },

  methods: {
    reset() {
      this.searchHcmUser = null;
    },

    input() {
      this.updateInput();
      this.$emit("autoSearch");
    },

    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchHcmUser,
        multiple: this.isMultiple,
        field: this.field,
        searchComponent: this,
      });
    },

    filter(key, list, value, option) {
      const { multiple } = option;
      return list.filter((b) => {
        if (!value) {
          return true;
        } else {
          if (!multiple) return value.value === b[key];

          if (value.length === 0) return true;
          for (let i = 0; i < value.length; i++) {
            if (value[i].value === b[key]) return true;
          }

          return false;
        }
      });
    },

    hasFilter(value) {
      return value && value.length > 0;
    },

    toExcel(temp, col, value) {
      return temp.hcmUserMapByHcmUserId[value];
    },
    clearSearchValue() {
      return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
