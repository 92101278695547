<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs" v-text="title" />
    <q-markup-table dense flat separator="none">
      <thead>
        <tr>
          <th style="text-align: left">{{ getRes('Form.Field.Applicant') }}</th>
          <th style="text-align: left">{{ getRes('Form.Field.RepliedOn') }}</th>
          <th style="text-align: left">{{ getRes('Form.Field.Action') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="q-tr--no-hover"
          v-for="tenderer in record"
          :key="tenderer.name"
        >
          <td v-text="tenderer.name" />
          <td v-if="tenderer.submit">
            {{
              $proSmart.common.getFormattedDate(new Date(tenderer.dateSubmit))
            }}
          </td>
          <td v-else>----</td>
          <td v-if="tenderer.submit">
            <q-badge
              color="primary"
              v-if="tenderer.actionType === 'ButtonAction'"
              >{{ getRes('System.Button.EmailButton') }}
            </q-badge>
            <q-badge outline color="primary" v-else>{{ getRes('System.Button.Login') }}</q-badge>
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script>
export default {
  name: "ButtonLogAuditTrail",
  props: {
    title: String,
    record: Array,
  },
};
</script>
