<template>
  <div>
    <div class="row" v-if="modelArr.length === 1">
      <div class="col-12">
        <q-skeleton
          type="rect"
          width="100%"
          height="100px"
          style="margin-top: 0"
        />
      </div>
    </div>

    <div class="q-gutter-md row" v-else>
      <template v-for="(model, index) in modelArr">
        <q-select
          :key="index"
          dense
          outlined
          clearable
          options-dense
          style="width: 260px"
          v-model="model.innerValue"
          :options="model.options"
          @input="handleSelect($event, index)"
          :label="
            model.innerValue ? '' : $t('Form.Section.ProcurementCategoriesFor')
          "
        >
          <template v-slot:after v-if="index !== modelArr.length - 1">
            <q-icon name="chevron_right" style="margin-right: -14px" />
          </template>
        </q-select>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "dropDownList-commodity",
  props: {
    dropDownList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modelArr: [],
      treeObjectList: [],
      commodityNodeMap: {},
      commodityDescription: {},
      lastItem: {},
      modelNumber: 1,
    };
  },

  mounted() {
    this.updateModelArr(this.modelNumber);
    this.onDropDownListChange();
  },
  methods: {
    updateModelArr(number) {
      const modelArr = [];
      for (let i = 0; i < number; i++) {
        modelArr.push({
          innerValue: "",
          options: [],
        });
      }
      this.modelArr = modelArr;
    },

    reset() {
      this.modelArr.forEach((item) => (item.innerValue = ""));
    },

    getSortedDropDownList() {
      const temp = [...this.dropDownList];
      return temp;
    },

    createTree() {
      const trees = [];
      const sortedDropDownList = this.getSortedDropDownList();
      // set a {code: {label,code,children,parent}} Map
      this.commodityNodeMap = sortedDropDownList.reduce(
        (obj, { code, description, originalCode }) => ({
          ...obj,
          [code]: {
            label: `${originalCode} - ${description}`,
            value: description,
            code,
            children: [],
            parent: null,
          },
        }),
        {}
      );
      Object.keys(this.commodityNodeMap).forEach((key) => {
        const node = this.commodityNodeMap[key];
        const splitCode = node.code.split("-");

        if (splitCode.length === 1) {
          trees.push(node);
        } else {
          //code = '1-1-1', code.parentCode = '1-1'
          const parent =
            this.commodityNodeMap[splitCode.slice(0, -1).join("-")];
          parent.children.push(node);
          node.parent = parent;
        }
      });
      return trees;
    },

    onDropDownListChange() {
      this.treeObjectList = this.createTree();
      this.modelArr[0].options = this.treeObjectList.map((item) => ({
        children: item.children,
        code: item.code,
        value: item.value,
        label: item.label,
      }));
    },

    handleSelect(item, index) {
      if (item) {
        this.lastItem = item;
      }

      this.modelArr.forEach((model, modelIndex) => {
        if (modelIndex > index) {
          model.innerValue = "";
          model.options = [];
        }
      });

      item &&
        index <= this.modelNumber - 2 &&
        (this.modelArr[index + 1].options = item.children.map((child) => ({
          children: child.children,
          code: child.code,
          value: child.value,
          label: child.label,
        })));

      if (!item) {
        if (
          index > 0 &&
          !!this.lastItem &&
          this.lastItem.code.split("-")[index - 1]
        ) {
          this.$emit("getFilterCode", {
            val: this.lastItem.code.split("-")[index - 1],
          });
        } else {
          this.$emit("getFilterCode", { val: "" });
        }
      } else {
        this.$emit("getFilterCode", { val: item.code });
      }
    },
    findMaxCommodityListLevel(list) {
      let codeLevel = [true, false, false, false, false];

      for (let i = 0; i < list.length; i++) {
        if (!codeLevel[0] && list[i].code1) codeLevel[0] = true;
        if (!codeLevel[1] && list[i].code2) codeLevel[1] = true;
        if (!codeLevel[2] && list[i].code3) codeLevel[2] = true;
        if (!codeLevel[3] && list[i].code4) codeLevel[3] = true;

        if (codeLevel[0] && codeLevel[1] && codeLevel[2] && codeLevel[3]) break;
      }

      return codeLevel.filter((x) => x).length;
    },
  },
  watch: {
    dropDownList(list) {
      this.modelNumber = this.findMaxCommodityListLevel(list);
      this.updateModelArr(this.modelNumber);
      list.length && this.onDropDownListChange();
    },
  },
};
</script>
