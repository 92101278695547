import ProReportBooleanField from "./ProReportBooleanField.vue";
import ProReportChipField from "./ProReportChipField.vue";
import ProReportCommodityDropDownListField from "./ProReportCommodityDropDownListField.vue";
import ProReportMultipleCommodityDropDownListField from "./ProReportMultipleCommodityDropDownListField.vue";
import ProReportCurrencyField from "./ProReportCurrencyField.vue";
import ProReportDateField from "./ProReportDateField.vue";
import ProReportDateTimeField from "./ProReportDateTimeField.vue";
import ProReportDateTimeNullField from "./ProReportDateTimeNullField.vue";
import ProReportDocumentStatusField from "./ProReportDocumentStatusField.vue";
import ProReportSupplierStatusField from "./ProReportSupplierStatusField.vue";
import ProReportHcmUserIdField from "./ProReportHcmUserIdField.vue";
import ProReportFileSizeField from "./ProReportFileSizeField.vue";
import ProReportFloatField from "./ProReportFloatField.vue";
import ProReportI18nField from "./ProReportI18nField.vue";
import ProReportIntField from "./ProReportIntField.vue";
import ProReportMultipleChipField from "./ProReportMultipleChipField.vue";
import ProReportTextField from "./ProReportTextField.vue";
import ProReportSubReportField from "./ProReportSubReportField.vue";

export const componentMap = {
  boolean: ProReportBooleanField,
  chip: ProReportChipField,
  commodity: ProReportCommodityDropDownListField,
  multipleCommodity: ProReportMultipleCommodityDropDownListField,
  currency: ProReportCurrencyField,
  date: ProReportDateField,
  datetime: ProReportDateTimeField,
  datetimeNull: ProReportDateTimeNullField,
  documentStatus: ProReportDocumentStatusField,
  supplierStatus: ProReportSupplierStatusField,
  hcmUserId: ProReportHcmUserIdField,
  fileSize: ProReportFileSizeField,
  float: ProReportFloatField,
  i18n: ProReportI18nField,
  number: ProReportIntField,
  multipleChip: ProReportMultipleChipField,
  string: ProReportTextField,
  subProReport: ProReportSubReportField,
};

const allSearchField = {
  ProReportBooleanField,
  ProReportChipField,
  ProReportCommodityDropDownListField,
  ProReportMultipleCommodityDropDownListField,
  ProReportCurrencyField,
  ProReportDateField,
  ProReportDateTimeField,
  ProReportDateTimeNullField,
  ProReportDocumentStatusField,
  ProReportSupplierStatusField,
  ProReportHcmUserIdField,
  ProReportFileSizeField,
  ProReportFloatField,
  ProReportI18nField,
  ProReportIntField,
  ProReportMultipleChipField,
  ProReportTextField,
  ProReportSubReportField,
};

export default allSearchField;
