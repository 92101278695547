<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-10">
      <div v-if="!module">{{ $t("ErrorMessage.DocumentModuleNotFound") }}</div>
      <div v-else>
        <q-chip
          role="button"
          v-for="(item, key) in this.codeTableTemp.documentStatusMapping[
            module
          ]"
          :key="key"
          color="primary"
          :selected.sync="searchChip[key]"
          :text-color="searchChip[key] ? 'white' : 'primary'"
          :outline="!searchChip[key]"
          @click="
            isShowAll = false;
            input();
          "
          >{{ $t(item) }}
        </q-chip>
        <q-icon
          class="cursor-pointer"
          :name="isShowAll ? 'PROSmart-NoShow' : 'PROSmart-Show'"
          style="font-size: 2.5em; color: rgba(0, 0, 0, 0.54)"
          @click="showChip"
        >
        </q-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportTenderStatusField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },
    module: {
      type: String,
      required: true,
    },
    codeTableTemp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchChip: {},
      type: "documentStatus",
      isShowAll: true,
    };
  },
  created() {
    this.reset();
    this.updateInput();
  },
  methods: {
    showChip() {
      this.isShowAll = !this.isShowAll;
      this.reset(this.isShowAll);
      this.input();
    },
    reset(selected = true) {
      for (let statusTextMapElementKey in this.codeTableTemp
        .documentStatusMapping[this.module]) {
        this.searchChip[statusTextMapElementKey] = selected;
      }
    },
    toExcel(temp, col, value) {
      return temp.documentStatusMapping[col.module][value];
    },
    input() {
      this.$forceUpdate();
      this.updateInput();

      this.$emit("autoSearch");
    },
    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchChip,
        field: this.field,
        searchComponent: this,
      });
    },
    filter(key, list, value) {
      if (list.length === 0) return list;

      return list.filter((b) => value[b[key]]);
    },

    hasFilter(value) {
      return value && Object.keys(value).some((key) => !value[key]);
    },

    clearSearchValue(value) {
      for (let valueKey in value) {
        value[valueKey] = true;
      }

      return value;
    },
  },
  watch: {
    module() {
      this.reset();
      this.updateInput();
    },
  },
};
</script>
