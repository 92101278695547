<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>
    <div class="col-5">
      <pro-smart-date
        v-model="searchDate.from"
        date-type="date"
        style="margin-right: 16px"
        @input="input"
      ></pro-smart-date>
    </div>
    <div class="col-5">
      <pro-smart-date
        v-model="searchDate.to"
        date-type="date"
        style="margin-left: 16px"
        @input="input"
      ></pro-smart-date>
    </div>
  </div>
</template>

<script>
import ProSmartDate from "@/components/PROSmart/ProSmartDate";

export default {
  name: "ProReportDateTimeField.vue",
  components: { ProSmartDate },
  props: {
    label: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    value: null,
  },
  data() {
    return {
      searchDate: { from: null, to: null },
      error: { form: "", to: "" },
      type: "date",
    };
  },
  methods: {
    reset() {
      this.searchDate = { from: null, to: null };
      this.error = { from: null, to: null };
    },
    input() {
      this.$emit("input", {
        type: this.type,
        value: this.searchDate,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },
    filter(key, list, value) {
      if (!value.from && !value.to) {
        return list;
      }

      if (!value.from && value.to) {
        return list.filter((b) => {
          return b[key] <= new Date(value.to).setHours(23, 59, 59, 999);
        });
      } else if (value.from && !value.to) {
        return list.filter((b) => {
          return new Date(value.from) <= b[key];
        });
      } else {
        return list.filter((b) => {
          return (
            new Date(value.from) <= b[key] &&
            b[key] <= new Date(value.to).setHours(23, 59, 59, 999)
          );
        });
      }
    },

    hasFilter(value) {
      return value.from || value.to;
    },

    convert(value) {
      return new Date(value);
    },
    format(val) {
      return this.$proSmart.common.getFormattedDate(val);
    },
    clearSearchValue() {
      return { from: null, to: null };
    },
  },
};
</script>
