<template>
  <div class="row items-center no-wrap" style="width: 100%">
    <div v-if="showDownloadButtonOnly">
      <q-btn
        no-caps
        dense
        round
        flat
        icon="download"
        :loading="loading"
        @click="handleDownload(file)"
      />
    </div>
    <div v-else class="col row">
      <div
        class="col-12 row justify-between"
        style="font-size: 17px"
        v-if="file.description"
      >
        <span
          :class="{ 'soft-delete-file': file.status === 'SoftDelete' }"
          class="text-primary"
        >
          {{ file.description }}
        </span>
      </div>
      <div v-else style="height: 17px; width: 17px"></div>
      <div class="col-12 row justify-between">
        <span class="col ellipsis q-ml-md" style="font-size: 14px">
          <span
            v-if="isShowFilename"
            :class="file.status === 'SoftDelete' ? 'soft-delete-file' : ''"
          >
            {{ file.fileName || file.filename }}
          </span>
        </span>

        <div class="col-auto">
          {{ getFormattedSize(file.fileSize || file.filesize || file.size) }}
        </div>
      </div>

      <div
        v-if="file.md5"
        class="col-12 ellipsis q-ml-md text-subtitle2"
        style="color: #8c939d; font-size: 12px"
      >
        {{ `MD5: ${file.md5}` }}
      </div>
    </div>

    <div v-if="showDownloadButton && showBtn" class="col-auto q-ml-md">
      <q-btn
        no-caps
        dense
        round
        flat
        icon="download"
        :loading="loading"
        @click="handleDownload(file)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    showDownloadButton: {
      type: Boolean,
      default: () => false,
    },
    isShowFilename: {
      type: Boolean,
      default: () => true,
    },
    onDownloadFile: Function,
    showDownloadButtonOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    //Show “Download” button if file size less than 1GB
    showBtn() {
      return (
        this.file?.fileSize < 1048576 * 1024 || this.file?.size < 1048576 * 1024
      );
    },
  },
  methods: {
    handleDownload(file) {
      if (this.onDownloadFile) {
        this.onDownloadFile(file);
      } else {
        this.loading = true;

        let tenderId = this.$route.params.id ? this.$route.params.id : 0;

        this.$proSmart.download
          .downloadFileStream(this, tenderId, file.fileId)
          .finally(() => {
            this.loading = false;
          });

        // this.$proSmart.download.downloadFile(
        //   this,
        //   this.$route.params.id,
        //   file.fileId
        // );
      }
    },

    getFormattedSize(size) {
      return this.$proSmart.common.getSizeString(size);
    },
  },
};
</script>

<style scoped lang="scss">
.soft-delete-file {
  color: #c10015 !important;
  text-decoration: line-through;
}
</style>
