<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs">
      {{ $t("Form.Section.TenderOpeningWitness") }}
    </div>
    <div class="q-mt-sm q-mb-xs witness_table" v-if="openingWitnessInfo">
      <pro-key-value-table :rows="tableRows" :data="openingWitnessInfo" />
    </div>
  </div>
</template>

<script>
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";

export default {
  name: "OpeningWitnessAuditTrail",
  components: {
    ProKeyValueTable,
  },
  props: {
    openingWitnessInfo: {
      type: Object,
      require: true,
    },
  },

  computed: {
    tableRows() {
      return [
        {
          label: "Form.Field.WitnessNameEn",
          field: "witnessNameEng",
          _style: "width:184px",
        },
        {
          label: "Form.Field.WitnessNameCn",
          field: "witnessNameChi",
          _style: "width:184px",
          hide: true,
        },
        {
          label: "Form.Field.WitnessTitle",
          field: "witnessTitle",
          _style: "width:184px",
        },
        {
          label: "Form.Field.WitnessEmail",
          field: "emailAddress",
          _style: "width:184px",
        },
        {
          label: "Form.Field.UsingIPAddress",
          field: "ip",
          _style: "width:184px",
        },
        {
          label: "Form.Field.Sent",
          field: "sentDate",
          format: this.$proSmart.common.getFormattedDate,
          _style: "width:184px",
        },
        {
          label: "Form.Field.Signed",
          field: "signedDate",
          format: this.$proSmart.common.getFormattedDate,
          _style: "width:184px",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.witness_table {
  padding-left: 16px;
}
</style>
