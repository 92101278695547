<template>
  <q-markup-table dense flat separator="none">
    <thead></thead>
    <tbody>
      <template v-for="(row, index) in rows">
        <tr
          v-if="row.hide ? !!data[row.field] : true"
          :key="index"
          class="q-tr--no-hover"
        >
          <td :class="row._class" :style="row._style">
            {{ `${$t(row.label)}${labelSuffix}` }}
          </td>
          <td
            :class="row.class"
            :style="row.style"
            v-text="
              typeof row.format === 'function'
                ? row.format(data[row.field])
                : data[row.field]
            "
          />
        </tr>
      </template>
    </tbody>
  </q-markup-table>
</template>

<script>
export default {
  name: "ProKeyValueTable",
  props: {
    /** @type {
     * Array<{label: string, field: string, format?: function(*): string, style?: string}>} */
    rows: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    labelSuffix: {
      type: String,
      default: ":",
    },
  },
};
</script>

<style lang="scss" scoped>
.q-table {
  td:first-child {
    padding-left: 0;
  }
}
</style>
