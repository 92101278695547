<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs">
      {{ getRes("Form.Field.EmailLog") }}
    </div>
    <div style="padding-left: 10px; padding-bottom: 10px">
      <div class="row no-wrap items-center q-col-gutter-md">
        <div class="text-h6 col-6">
          {{ getRes("Form.Field.Applicant") }}
        </div>
        <div class="text-h6 col-1"></div>
        <div class="text-h6 col-2">{{ getRes("CustMenu.Col.SendTime") }}</div>
        <div class="text-h6 col-3">
          {{ getRes("CustMenu.Col.ErrorDescription") }}
        </div>
      </div>
    </div>
    <div
      class="q-mt-sm q-mb-xs"
      v-if="email.length > 0 && email[0].emailType === 'tenderer'"
    >
      <div
        style="padding-left: 10px; padding-bottom: 10px"
        v-for="email in email"
        :key="email.name"
      >
        <div class="row no-wrap items-center q-col-gutter-md">
          <div class="col-6">
            <div class="text-h5" v-text="email.name" />
          </div>
          <div class="col-1" v-if="getSendMark(email.mainAddresses) === 'Y'">
            <q-badge color="primary">{{ getRes("Form.Field.Sent") }}</q-badge>
          </div>
          <div class="col-2">
            {{ getSendTime(email.mainAddresses) }}
          </div>
          <div class="col-3">
            {{ getErrorMessage(email.mainAddresses) }}
          </div>
        </div>
        <div style="padding-left: 10px">
          <div v-if="email.mainAddresses">To: {{ email.mainAddresses }}</div>
          <div v-if="email.otherAddresses">CC: {{ email.otherAddresses }}</div>
        </div>
      </div>
    </div>
    <div
      class="q-mt-sm q-mb-xs"
      v-if="email.length > 0 && email[0].emailType === 'procurer'"
    >
      <div
        style="padding-left: 10px; padding-bottom: 10px"
        v-for="email in email"
        :key="email.name"
      >
        <div class="row no-wrap items-center q-col-gutter-md">
          <div class="col-6">
            <div class="text-h5" v-text="email.name" />
          </div>
          <div class="col-1" v-if="getSendMark(email.mainAddresses) === 'Y'">
            <q-badge color="primary">{{ getRes("Form.Field.Sent") }}</q-badge>
          </div>
          <div class="col-2">
            {{ getSendTime(email.mainAddresses) }}
          </div>
          <div class="col-3">
            {{ getErrorMessage(email.mainAddresses) }}
          </div>
        </div>
        <div style="padding-left: 10px">
          <div v-if="email.mainAddresses">To: {{ email.mainAddresses }}</div>
          <div v-if="email.otherAddresses">CC: {{ email.otherAddresses }}</div>
        </div>
      </div>
    </div>
    <div
      class="q-mt-sm q-mb-xs"
      v-if="email.length > 0 && email[0].emailType === 'hcm'"
    >
      <div
        style="padding-left: 10px; padding-bottom: 10px"
        v-for="email in email"
        :key="email.name"
      >
        <div class="row no-wrap items-center q-col-gutter-md">
          <div class="col-6" v-if="email.name">
            <div class="text-h5" v-text="email.name" />
          </div>
          <div class="col-1" v-if="getSendMark(email.mainAddresses) === 'Y'">
            <q-badge color="primary">{{ getRes("Form.Field.Sent") }}</q-badge>
          </div>
          <div class="col-2">
            {{ getSendTime(email.mainAddresses) }}
          </div>
          <div class="col-3">
            {{ getErrorMessage(email.mainAddresses) }}
          </div>
        </div>
        <div style="padding-left: 10px">
          <div v-if="email.mainAddresses">
            {{ getRes("Form.Field.to") }}: {{ email.mainAddresses }}
          </div>
          <div v-if="email.otherAddresses">
            {{ getRes("Form.Field.cc") }}: {{ email.otherAddresses }}
          </div>
          <div v-if="email.sendTime">
            {{ getRes("Form.Field.sendTime") }}: {{ email.sendTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailLogAuditTrail",
  props: {
    email: Array,
    emailInfo: Array,
  },
  methods: {
    getEmailObject(to) {
      return this.emailInfo.filter((b) => b.emailTo === to);
    },
    getSendTime(to) {
      let emailObject = this.getEmailObject(to);
      return emailObject.length > 0
        ? this.$proSmart.common.getFormattedDate(emailObject[0].sendTime)
        : "";
    },
    getErrorMessage(to) {
      let emailObject = this.getEmailObject(to);
      return emailObject.length > 0 ? emailObject[0].errorDescription : "";
    },
    getSendMark(to) {
      let emailObject = this.getEmailObject(to);
      return emailObject.length > 0 ? emailObject[0].sendMark : "";
    },
  },
};
</script>
