<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-5">
      <q-input
        outlined
        dense
        type="number"
        v-model.number="searchInt.min"
        @input="input"
        prefix="Min"
        style="margin-right: 16px"
      >
      </q-input>
    </div>
    <div class="col-5">
      <q-input
        outlined
        dense
        type="number"
        v-model.number="searchInt.max"
        @input="input"
        prefix="Max"
        style="margin-left: 16px"
      ></q-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportIntField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchInt: { min: null, max: null },
      type: "number",
    };
  },
  methods: {
    reset() {
      this.searchInt = { min: null, max: null };
    },
    input() {
      this.$emit("input", {
        type: this.type,
        value: this.searchInt,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },
    filter(key, list, value) {
      return list.filter((b) => {
        if (
          this.isUndefinedNullAndEmpty(value.min) &&
          this.isUndefinedNullAndEmpty(value.max)
        ) {
          return true;
        } else if (this.isUndefinedNullAndEmpty(value.min)) {
          return b[key] <= value.max;
        } else if (this.isUndefinedNullAndEmpty(value.max)) {
          return b[key] >= value.min;
        } else {
          return value.min <= b[key] && b[key] <= value.max;
        }
      });
    },

    hasFilter(value) {
      return (
        !this.isUndefinedNullAndEmpty(value.min) ||
        !this.isUndefinedNullAndEmpty(value.max)
      );
    },

    isUndefinedNullAndEmpty(obj) {
      return obj === undefined || obj === null || obj === "";
    },
    clearSearchValue() {
      return { min: null, max: null };
    },
  },
};
</script>
