<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs">
      {{ getRes("Form.Section.ErrorMessage") }}
    </div>
    <div class="q-mt-sm q-mb-xs" style="padding-left: 16px" v-if="errorMessage">
      <pro-key-value-table :rows="tableRows" :data="errorMessageInfo" />
    </div>
  </div>
</template>

<script>
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";
export default {
  name: "ErrorMessageAuditTrail",
  components: {
    ProKeyValueTable,
  },
  props: {
    errorMessage: String,
  },
  data() {
    return {
      errorMessageInfo: {},
    };
  },
  created() {
    this.errorMessageInfo["errorMessage"] = this.errorMessage;
  },
  computed: {
    tableRows() {
      return [
        {
          label: "Form.Field.Message",
          field: "errorMessage",
          _style: "width:184px",
        },
      ];
    },
  },
};
</script>
