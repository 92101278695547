<template>
  <div>
    <q-input
      square
      v-if="!previewMode"
      v-model="date"
      outlined
      dense
      :error-message="errorMessage"
      :error="!isValid"
      :mask="format.replaceAll(/[YMDHm]/g, '#')"
      :label="hintFormat"
      @input="handleInputChange"
    >
      <template v-slot:prepend>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy
            v-model="popup"
            ref="qPopupProxy"
            transition-show="scale"
            transition-hide="scale"
            @hide="panel = 'date'"
            cover
          >
            <q-tab-panels
              v-model="panel"
              transition-prev="jump-right"
              transition-next="jump-left"
              animated
            >
              <q-tab-panel name="date" style="padding: 0">
                <q-date
                  v-model="date"
                  :mask="format"
                  :options="optionsFn"
                  @input="handleDateChange"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-tab-panel>
              <q-tab-panel name="time" style="padding: 0">
                <q-time
                  @input="handleTimeChange"
                  v-model="date"
                  :mask="format"
                  format24h
                  :options="optionsTime"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-time>
              </q-tab-panel>
            </q-tab-panels>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
    <q-field v-else outlined dense square>
      <template v-slot:control>
        <!-- {{ value ? formatDate(new Date(value)) : '' }} -->
        {{ date ? date : "" }}
      </template>
    </q-field>
  </div>
</template>

<script>
import moment from "moment";

const baseLocale = "zh-HK";

export default {
  name: "pro-smart-date",
  props: {
    dateType: {
      type: String,
      default: () => "date",
      validator: (val) => ["date", "datetime"].includes(val),
    },
    previewMode: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: String,
    },
    allowNonWorkingDays: {
      type: Boolean,
      default: () => false,
    },
    disabledDates: {
      type: Function,
      default: () => true,
    },
    disabledTime: {
      type: Function,
      default: () => true,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    minDaysValidation: {
      type: Boolean,
      default: () => false,
    },
    minInitValidation: {
      type: Boolean,
      default: () => false,
    },
    minDaysAfterInit: {
      type: Number,
      default: 1,
    },
    defaultTime: {
      type: String,
    },
  },
  data() {
    return {
      panel: "date",
      date: null,
      nonWorkingDates: [],
      renderTimePicker: false,
      errorMessage: "",
      popup: false,
    };
  },
  methods: {
    changeTime(date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    dateCompare(date, nowDate) {
      if (this.dateType == "date") {
        return (
          Date.parse(this.changeTime(date)) <
          Date.parse(this.changeTime(nowDate))
        );
      } else {
        return new Date(date).getTime() < nowDate.getTime();
      }
    },
    handleInputChange(val) {
      let isoString = null;
      if (!val) {
        this.errorMessage = this.required
          ? this.$t("Form.Validation.Empty")
          : "";
      } else {
        this.errorMessage = "";
        const date = new Date(
          this.date + (this.dateType === "datetime" ? "" : " 00:00")
        );
        isoString = this.$proSmart.common.getISODateString(date);
        this.validateDate(date);
      } 
      this.$emit("input", isoString);
    },

    validateDate(val) {
      const formattedDate = new moment(val, this.format);
      const dateStr = this.formatDate(new Date(val));
      const afterDay = new Date(
        new Date() - this.minDaysAfterInit * (24 * 60 * 60 * 100)
      );
      switch (true) {
        case val !== "" && !formattedDate.isValid():
          this.errorMessage = this.$t("Form.Validation.InvalidDate");
          break;
        case !this.isWorkingDay(val):
          this.errorMessage = `${dateStr} ${this.$t(
            "Form.Message.Error.NotWorkingDay"
          )}`;
          break;
        case !this.disabledDates(val):
          this.errorMessage = `${dateStr} ${this.$t(
            "Form.Validation.hasExpired"
          )}`;
          break;
        case !this.disabledTime(val):
          this.errorMessage = `${dateStr} ${this.$t(
            "Form.Validation.hasExpired"
          )}`;
          break;
        case this.minInitValidation:
          if (
            this.dateType === "datetime" &&
            this.dateCompare(val, new Date())
          ) {
            this.errorMessage = `${dateStr} ${this.$t(
              "Form.Validation.hasExpired"
            )}`;
          } else if (
            this.dateType === "date" &&
            this.dateCompare(val, new Date())
          ) {
            this.errorMessage = `${dateStr} ${this.$t(
              "Form.Validation.hasExpired"
            )}`;
          } else {
            this.errorMessage = "";
          }
          break;
        case this.minDaysValidation:
          if (this.dateCompare(val, afterDay)) {
            this.errorMessage = `${dateStr} ${this.$t(
              "Form.Validation.hasExpired"
            )}`;
          } else {
            this.errorMessage = "";
          }
          break;
        default:
          this.errorMessage = "";
      }
    },

    handleDateChange(date, reason) {
      if (reason == "remove-day") {
        this.$nextTick(() => this.$refs.qPopupProxy.hide());
      } else {
        this.handleInputChange(date);
        const isDateTime = this.dateType === "datetime";
        this.renderTimePicker = isDateTime;
        this.$nextTick(() => {
          this.$refs.qPopupProxy.hide();
          if (isDateTime) {
            this.panel = "time";
            this.$nextTick(() => {
              if (this.date && this.defaultTime)
                this.date =
                  this.date.slice(0, this.date.indexOf(" ") + 1) +
                  this.defaultTime;
              this.$refs.qPopupProxy.show();
            });
          }
        });
      }
    },
    handleTimeChange(val) {
      this.renderTimePicker = false;
      this.$nextTick(() => {
        this.$refs.qPopupProxy.hide();
        this.panel = "date";
      });
      this.handleInputChange(val);
    },

    isWorkingDay(date) {
      // If date is a String, parse to Date Object
      if (!(date instanceof Date) && typeof date === "string") {
        date = new Date(date);
      }

      return !this.nonWorkingDates.find(
        (nonWorkingDate) =>
          date.toLocaleDateString(baseLocale) ===
          nonWorkingDate.toLocaleDateString(baseLocale)
      );
    },

    optionsFn(date) {
      return (
        this.disabledDates(date) &&
        (this.allowNonWorkingDays || this.isWorkingDay(date))
      );
    },
    optionsTime(hr, min) {
      return this.disabledTime(hr, min);
    },
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(date);
    },
    initDate() {
      this.date = "";
    },
  },

  computed: {
    format() {
      switch (this.dateType) {
        case "date":
          return "YYYY-MM-DD";
        default:
          return "YYYY-MM-DD HH:mm";
      }
    },
    hintFormat() {
      switch (this.dateType) {
        case "date":
          return "yyyy-MM-dd";
        default:
          return "yyyy-MM-dd HH:mm";
      }
    },

    /**
     * @returns {boolean}
     */
    isValid() {
      return !this.errorMessage;
    },
  },

  watch: {
    value(newValue) {
      this.date = newValue ? new moment(this.value).format(this.format) : null;
    },
  },

  async beforeMount() {
    if (!this.allowNonWorkingDays) {
      const res = await this.$proSmart.codeTable.getNonWorkingDate(this);
      this.nonWorkingDates = res.map(
        ({ calendarDate }) => new Date(calendarDate)
      );
    }
    if (this.value) {
      this.date = new moment(this.value).format(this.format);
    }
    this.handleInputChange(this.value);
  },
};
</script>
