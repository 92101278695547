<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-5">
      <q-input
        outlined
        dense
        v-model.trim="searchI18n.key"
        @input="input"
        prefix="Key:"
        style="margin-right: 16px"
      />
    </div>
    <div class="col-5">
      <q-input
        outlined
        dense
        v-model.trim="searchI18n.value"
        @input="input"
        prefix="Value:"
        style="margin-left: 16px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportI18nField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },
  },

  created() {
    this.reset();
    this.updateInput();
  },

  data() {
    return {
      searchI18n: { key: null, value: null },
      type: "i18n",
    };
  },

  methods: {
    reset() {
      this.searchI18n = { key: null, value: null };
    },

    input() {
      this.updateInput();
      this.$emit("autoSearch");
    },

    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchI18n,
        field: this.field,
        searchComponent: this,
      });
    },

    filter(key, list, value, option) {
      const _this = option.searchComponent;
      return list.filter((b) => {
        let temp = _this.$t(b[key]);

        if (
          this.isUndefinedNullAndEmpty(value.key) &&
          this.isUndefinedNullAndEmpty(value.value)
        ) {
          return true;
        } else if (this.isUndefinedNullAndEmpty(value.key)) {
          //key is null
          return (
            this.isIncludes(b[key], value.value) ||
            this.isIncludes(temp, value.value)
          );
        } else if (this.isUndefinedNullAndEmpty(value.value)) {
          //value is null
          return this.isIncludes(b[key], value.key);
        } else {
          return (
            (this.isIncludes(b[key], value.value) ||
              this.isIncludes(temp, value.value)) &&
            this.isIncludes(b[key], value.key)
          );
        }
      });
    },

    hasFilter(value) {
      return (
        !this.isUndefinedNullAndEmpty(value.key) ||
        !this.isUndefinedNullAndEmpty(value.value)
      );
    },

    isIncludes(searchStr, value) {
      return (
        searchStr.includes(value) ||
        searchStr.toLowerCase().includes(value) ||
        searchStr === value
      );
    },

    isUndefinedNullAndEmpty(obj) {
      return obj === undefined || obj === null || obj === "";
    },

    clearSearchValue() {
      return { key: null, value: null };
    },
  },
};
</script>
