<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-10">
      <q-chip
        role="button"
        v-for="item in filterlist"
        :key="item.value"
        :color="item.color ? item.color : 'primary'"
        :selected.sync="searchChip[item.value]"
        :text-color="searchChip[item.value] ? 'white' : 'primary'"
        :outline="!searchChip[item.value]"
        @click="input()"
      >
        {{ $t(item.label) }}
      </q-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportBooleanField",
  props: {
    label: {
      type: String,
      required: true,
    },

    value: null,
    /** {{label: string, value: string}[]} */
    list: {
      type: Array,
      required: true,
      default: () => [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
    },

    showBoolNull: {
      type: Boolean,
      default: false,
    },

    showBoolEmpty: {
      type: Boolean,
      default: false,
    },

    field: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filterlist: null,
      searchChip: {},
      type: "boolean",
    };
  },

  created() {
    this.addEmptySelect();
    this.reset();
    this.updateInput();
  },

  methods: {
    initBoolList() {
      const temp = {};
      this.filterlist = this.list.reduce((pre, next) => {
        temp[next.value] ? "" : (temp[next.value] = true && pre.push(next));
        return pre;
      }, []);
    },

    reset() {
      if (!this.list) {
        return;
      }
      this.initBoolList();
      this.filterlist.forEach((item) => {
        if (!this.searchChip[item.value]) {
          this.searchChip[item.value] = true;
        }
      });
    },

    input() {
      this.$forceUpdate();
      this.updateInput();
      this.$emit("autoSearch");
    },

    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchChip,
        field: this.field,
        searchComponent: this,
      });
    },

    filter(key, list, value) {
      return list.filter((b) => value[b[key]]);
    },

    hasFilter(value) {
      return value && Object.keys(value).some((key) => !value[key]);
    },

    addEmptySelect() {
      if (this.showBoolNull) {
        this.list.push({
          label: "Form.Col.Null",
          value: null,
        });
        this.$emit("update:list", this.list);
      }
      if (this.showBoolEmpty) {
        this.list.push({
          label: "Form.Option.Empty",
          value: "",
        });
        this.$emit("update:list", this.list);
      }
    },
    clearSearchValue(value) {
      for (let valueKey in value) {
        value[valueKey] = true;
      }
      return value;
    },
    toExcel(temp, col, value) {
      return value ? "Yes" : "No";
    },
  },

  watch: {
    list() {
      this.addEmptySelect();
      this.reset();
      this.updateInput();
    },
  },
};
</script>
