<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>
    <div class="col-9">
      <drop-down-list
        ref="dropDownListRef"
        :dropDownList="dropDownList"
        @getFilterCode="input"
      />
    </div>
  </div>
</template>
<script>
import DropDownList from "@/components/PROSmart/DropDownList.vue";
import proSmart from "@pro-smart/hcm-custom-project-api-middleware";

const { codeTable } = proSmart;

export default {
  name: "ProReportCommodityDropDownListField",
  components: { DropDownList },
  props: {
    label: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dropDownList: [],
      columns: [
        {
          name: "description",
          label: this.getRes("Form.Col.CommodityCode"),
          align: "left",
          required: true,
          style: "min-width: 1230px",
          field: "description",
          sortable: true,
        },
        {
          name: "button",
          align: "right",
          label: this.getRes("Form.Col.Button"),
          field: "button",
          sortable: false,
        },
      ],
      filterCommodityCode: "",
      type: "commodity",
    };
  },
  beforeMount() {
    codeTable.getCommodityCode(this).then((b) => {
      this.dropDownList = b;
    });
  },
  methods: {
    reset() {
      this.$refs.dropDownListRef.reset();
    },
    filter(key, list, value) {
      if (!value) return list;

      return list.filter((t) => {
        if (!t.sourcingCategories) {
          t.sourcingCategories = t.ProcurementCategory ?? {
            code: [],
            value: [],
          };
        }
        if (!t.sourcingCategories.code) return false;

        let fullCommodity = t.sourcingCategories.code.join("-");
        return fullCommodity.startsWith(value);
      });
    },

    hasFilter(value) {
      return value !== "";
    },

    input(val) {
      this.$emit("input", {
        type: this.type,
        value: val.val,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },
    clearSearchValue() {
      return "";
    },
    toExcel(temp, col, value) {
      return value && value.value ? value.value.join(" > ") : "";
    },
  },
};
</script>
