<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-5">
      <q-input
        outlined
        dense
        type="number"
        min="0"
        v-model.number="searchFileSize.min"
        @input="input"
        prefix="Min"
        style="margin-right: 16px"
      >
        <template v-slot:after>
          <q-select
            style="width: 10.5rem"
            outlined
            dense
            clearable
            v-model="searchFileSize.minUnit"
            :options="units"
            @input="input"
          />
        </template>
      </q-input>
    </div>
    <div class="col-5">
      <q-input
        outlined
        dense
        type="number"
        min="0"
        v-model.number="searchFileSize.max"
        @input="input"
        prefix="Max"
        style="margin-left: 16px"
      >
        <template v-slot:after>
          <q-select
            style="width: 10.5rem"
            outlined
            dense
            clearable
            v-model="searchFileSize.maxUnit"
            :options="units"
            @input="input"
          />
        </template>
      </q-input>
    </div>
  </div>
</template>

<script>
const k = 1024;
const units = ["B", "KB", "MB", "GB"];

export default {
  name: "ProReportFileSizeField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      units,
      searchFileSize: { min: null, max: null, minUnit: null, maxUnit: null },
      minSize: null,
      maxSize: null,
      type: "fileSize",
    };
  },

  created() {
    this.reset();
    this.updateInput();
  },

  methods: {
    reset() {
      this.searchFileSize = {
        min: null,
        max: null,
        minUnit: null,
        maxUnit: null,
      };
    },

    input() {
      this.sizeUnitConvert();
      this.updateInput();
      this.$emit("autoSearch");
    },

    convert(num, unit) {
      if (num && unit)
        return Math.round(num) * Math.pow(k, units.indexOf(unit));
      return null;
    },

    sizeUnitConvert() {
      const { min, max, minUnit, maxUnit } = this.searchFileSize;
      this.minSize = this.convert(min, minUnit);
      this.maxSize = this.convert(max, maxUnit);
    },

    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: { minSize: this.minSize, maxSize: this.maxSize },
        field: this.field,
        searchComponent: this,
      });
    },

    filter(key, list, value) {
      const regx = /(\d+)(\D+)/;
      let size, matchs, temp;

      return list.filter((b) => {
        size = b[key] ? b[key] : "";
        matchs = size.match(regx) ?? [];
        temp = this.convert(matchs[1], matchs[2]);

        if (
          this.isUndefinedNullAndEmpty(value.minSize) &&
          this.isUndefinedNullAndEmpty(value.maxSize)
        ) {
          return true;
        } else if (this.isUndefinedNullAndEmpty(value.minSize)) {
          return temp && temp <= value.maxSize;
        } else if (this.isUndefinedNullAndEmpty(value.maxSize)) {
          return temp && temp >= value.minSize;
        } else {
          return temp && value.minSize <= temp && temp <= value.maxSize;
        }
      });
    },

    hasFilter(value) {
      return (
        !this.isUndefinedNullAndEmpty(value.minSize) ||
        !this.isUndefinedNullAndEmpty(value.maxSize)
      );
    },

    isUndefinedNullAndEmpty(obj) {
      return obj === undefined || obj === null || obj === "";
    },

    clearSearchValue() {
      return {
        min: null,
        max: null,
        minUnit: null,
        maxUnit: null,
      };
    },
  },
};
</script>
