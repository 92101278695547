<template>
  <div>
    <div class="text-h5 text-primary">{{ $t(label) }}</div>
    <q-separator style="margin-bottom: 16px" />
    <template v-for="col in enableSubColumnList">
      <pro-report-all-search-field
        :key="col"
        :col="col"
        :filter-option="filterOption"
        :code-table-temp="codeTableTemp"
        @handleAutoSearch="handleAutoSearch"
      />
    </template>
  </div>
</template>

<script>
import { componentMap } from "./index.js";
export default {
  name: "ProReportSubReportField",
  components: {
    ProReportAllSearchField: () => import("./ProReportAllSearchField.vue"),
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,
    field: {
      type: String,
      required: true,
    },

    subColumns: {
      type: Array,
      default: () => [],
    },

    codeTableTemp: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      filterOption: {},
      type: "subProReport",
    };
  },

  methods: {
    getTypeClass(type) {
      if (!componentMap[type]) console.error("Type Missing " + type);
      return componentMap[type].methods;
    },

    filter(key, list, value) {
      const res = [];
      const filterOption = {};
      let hasFilter = false;

      for (let filterOptionKey in value) {
        let option = value[filterOptionKey];
        const comp = this.getTypeClass(option.type);
        const has = comp.hasFilter(option.value);

        if (has) {
          hasFilter = true;
          filterOption[filterOptionKey] = value[filterOptionKey];
        }
      }

      if (!hasFilter) return list;

      list.forEach((item) => {
        if (!item[key]) return;

        let searchList = item[key]?.list || [];
        if (searchList.length == 0) return;

        for (let filterOptionKey in filterOption) {
          let option = filterOption[filterOptionKey];
          searchList = this.getTypeClass(option.type).filter(
            filterOptionKey,
            searchList,
            option.value,
            option,
            this.codeTableTemp
          );
        }

        if (searchList && searchList.length > 0) {
          item[key].list = searchList;
          res.push(item);
        }
      });

      return res;
    },

    handleAutoSearch() {
      this.$emit("input", {
        type: this.type,
        value: this.filterOption,
        field: this.field,
        searchComponent: this,
      });
      this.$emit("autoSearch");
    },

    toExcel(temp, col, value) {
      let obj = {};
      if (!value) return obj;

      col.subColumnHeader.forEach((item) => {
        let subToExcel = this.getTypeClass(item.type).toExcel;
        let colData = [];

        value.list.forEach((row) => {
          let rowValue = row[item.field];
          rowValue = subToExcel ? subToExcel(temp, item, rowValue) : rowValue;
          colData.push(rowValue);
        });
        obj[item.field] = colData;
      });

      return obj;
    },
  },

  computed: {
    enableSubColumnList() {
      return this.subColumns.filter((b) => b.defaultShow);
    },
  },
};
</script>

<style lang="scss" scoped></style>
