export default {
  ProReportBooleanTdField: () => import("./ProReportBooleanTdField.vue"),
  ProReportChipTdField: () => import("./ProReportChipTdField.vue"),
  ProReportCommodityDropDownListTdField: () =>
    import("./ProReportCommodityDropDownListTdField.vue"),
  ProReportMultipleCommodityDropDownListTdField: () =>
    import("./ProReportMultipleCommodityDropDownListTdField.vue"),
  ProReportCurrencyTdField: () => import("./ProReportCurrencyTdField.vue"),
  ProReportDocumentStatusTdField: () =>
    import("./ProReportDocumentStatusTdField.vue"),
  ProReportSupplierStatusTdField: () =>
    import("./ProReportSupplierStatusTdField.vue"),
  ProReportHcmUserIdTdField: () => import("./ProReportHcmUserIdTdField.vue"),
  ProReportFileSizeTdField: () => import("./ProReportFileSizeTdField.vue"),
  ProReportFloatTdField: () => import("./ProReportFloatTdField.vue"),
  ProReportI18nTdField: () => import("./ProReportI18nTdField.vue"),
  ProReportMultipleChipTdField: () =>
    import("./ProReportMultipleChipTdField.vue"),
  ProReportDateTimeNullTdField: () =>
    import("./ProReportDateTimeNullTdField.vue"),
  ProReportDateTimeTdField: () => import("./ProReportDateTimeTdField.vue"),
  ProReportSubReportTdField: () => import("./ProReportSubReportTdField.vue"),
};
