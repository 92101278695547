<template>
  <div>
    <div class="text-h4 q-mt-sm q-mb-xs">
      {{ $t("Form.Section.SupplierSignature") }}
    </div>
    <div
      v-if="supplierSignature"
      class="q-mt-sm q-mb-xs"
      style="padding-left: 16px"
    >
      <pro-key-value-table :data="supplierSignature" :rows="tableRows" />
    </div>
  </div>
</template>

<script>
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";

export default {
  name: "SupplierSignatureAuditTrail",
  components: {
    ProKeyValueTable,
  },
  data() {
    return {};
  },

  props: {
    supplierSignature: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tableRows() {
      return [
        {
          label: "Form.Field.HcmUserId",
          field: "supplierHcmUserId",
          _style: "width:184px",
        },
        {
          label: "Form.Field.CompanyNameEnglish",
          field: "cmpyNameEng",
          _style: "width:184px",
        },
        {
          label: "Form.Field.CompanyNameChinese",
          field: "cmpyNameChi",
          _style: "width:184px",
        },
        {
          label: "Form.Field.NewContactEmail",
          field: "emailAddress",
          _style: "width:184px",
        },
        {
          label: "Form.Field.UsingIPAddress",
          field: "ip",
          _style: "width:184px",
        },
        {
          label: "Form.Field.Sent",
          field: "sendDate",
          format: this.$proSmart.common.getFormattedDate,
          _style: "width:184px",
        },
        {
          label: "Form.Field.Signed",
          field: "signedDate",
          format: this.$proSmart.common.getFormattedDate,
          _style: "width:184px",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
