<template>
  <div class="row q-pb-sm">
    <div class="col-2 cust-label-container">
      <label>{{ $t(label) }}</label>
    </div>

    <div class="col-5">
      <q-input
        outlined
        dense
        v-model="searchCurrency.min"
        v-money="searchCurrency.minFormat"
        @input="input"
        prefix="Min"
        style="margin-right: 16px"
      />
    </div>
    <div class="col-5">
      <q-input
        outlined
        dense
        v-model="searchCurrency.max"
        v-money="searchCurrency.maxFormat"
        @input="input"
        prefix="Max"
        style="margin-left: 16px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportCurrencyField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: null,

    field: {
      type: String,
      required: true,
    },

    prefix: {
      type: String,
      default: "$ ",
    },

    precision: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      searchCurrency: {
        min: null,
        max: null,
        minFormat: null,
        maxFormat: null,
      },
      type: "currency",
    };
  },

  created() {
    this.reset();
    this.updateInput();
  },

  methods: {
    reset() {
      this.searchCurrency = {
        min: null,
        max: null,
        minFormat: null,
        maxFormat: null,
      };
    },

    input() {
      this.setMoneyFormat();
      this.updateInput();
      this.$emit("autoSearch");
    },

    convert(currencyStr, rowValue, colSetting) {
      if (colSetting.operation) {
        return colSetting.operation(rowValue);
      }

      if (currencyStr) return parseFloat(currencyStr.replace(/[^\d.]/g, ""));
      return 0;
    },

    setMoneyFormat() {
      const { min, max } = this.searchCurrency;
      if (min) {
        this.$set(this.searchCurrency, "minFormat", {
          decimal: ".",
          thousands: ",",
          prefix: this.prefix,
          precision: this.precision,
        });
      }
      if (max) {
        this.$set(this.searchCurrency, "maxFormat", {
          decimal: ".",
          thousands: ",",
          prefix: this.prefix,
          precision: this.precision,
        });
      }

      if (this.convert(min) === 0 && this.convert(max) === 0) {
        this.searchCurrency = {
          min: null,
          max: null,
          minFormat: null,
          maxFormat: null,
        };
      } else if (this.convert(min) === 0) {
        this.searchCurrency.min = null;
        this.searchCurrency.minFormat = null;
      } else if (this.convert(max) === 0) {
        this.searchCurrency.max = null;
        this.searchCurrency.maxFormat = null;
      }
    },

    updateInput() {
      this.$emit("input", {
        type: this.type,
        value: this.searchCurrency,
        field: this.field,
        searchComponent: this,
      });
    },

    filter(key, list, value) {
      return list.filter((b) => {
        let money = b[key];
        if (
          (this.isUndefinedNullAndEmpty(value.min) &&
            this.isUndefinedNullAndEmpty(value.max)) ||
          (this.convert(value.min) === 0 && this.convert(value.max) === 0)
        ) {
          return true;
        } else if (
          this.isUndefinedNullAndEmpty(value.min) ||
          this.convert(value.min) === 0
        ) {
          return money <= this.convert(value.max);
        } else if (
          this.isUndefinedNullAndEmpty(value.max) ||
          this.convert(value.max) === 0
        ) {
          return money >= this.convert(value.min);
        } else {
          return (
            this.convert(value.min) <= money && money <= this.convert(value.max)
          );
        }
      });
    },

    hasFilter(value) {
      return (
        !this.isUndefinedNullAndEmpty(value.min) ||
        !this.isUndefinedNullAndEmpty(value.max)
      );
    },

    isUndefinedNullAndEmpty(obj) {
      return obj === undefined || obj === null || obj === "";
    },

    clearSearchValue() {
      return {
        min: null,
        max: null,
        minFormat: null,
        maxFormat: null,
      };
    },
  },
};
</script>
